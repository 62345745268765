import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { BinaryData } from '@/types'

export const useImagesYarosStore = defineStore('imagesYaros', {
  state: () => ({
    image: '' as string
  }),
  actions: {
    async getImage (store: string, id: string, width?: number) {
      try {
        const encodedStore = encodeURIComponent(store)
        const params = width
          ? { store: encodedStore, id, width }
          : { store: encodedStore, id }

        const response = await axiosMain.get<string>('/@image_store', {
          params
        })

        return response.data || ''
      } catch (error) {
        const axiosError = error as AxiosError
        console.error('Error fetching image:', axiosError.message)
        return ''
      }
    },
    async postImageAction (blob: BinaryData) {
      try {
        const response = await axiosMain.post<string | undefined>('/@image_store',
          blob, {
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          })
        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  }
})
