<template>
  <div class="org-info-wrapper">
    <div class="org-info-plan"
      :style="{background: gradientStyle}"
    >
      <div class="org-info-plan-icon-wrapper"
        v-if="state.organization.plan && state.organization.plan.planId !== '60a65616-7b11-11eb-80e9-000c29c2272a'"
      >
        <div class="org-info-plan-ellipse"></div>
        <div>
          <img :src="flame" class="org-info-plan-icon">
        </div>
      </div>
      <span class="org-info-plan-name">
        {{ state.organization.plan.name }}
      </span>
    </div>
    <div v-if="state.organization.logo === '' || state.organization.photoStorage === ''" class="org-info-empty-img">
      <img :src=noProfile>
    </div>
    <div class="org-info-img" v-else>
      <img :src="imageSrc == '' ? noProfile : `data:image/jpeg;base64, ${imageSrc}`">
    </div>
    <span class="org-info-org-name">
      {{ state.organization.name }}
    </span>
  </div>
</template>

<script setup lang="ts">
import flame from '@/assets/icon/flame.svg'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { ref, watch, onBeforeMount, computed, reactive } from 'vue'
import noProfile from '@/assets/images/no_org_image.svg'
import { Organization } from '@/models'

const imagesStore = useImagesYarosStore()

const imageSrc = ref('')

const state = reactive({
  organization: {} as Organization
})

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
})

const gradientStyle = computed(() => {
  if (state.organization.plan) {
    const { gradientLow, gradientTop } = state.organization.plan
    return `linear-gradient(90deg, rgba(${gradientLow}, 1) 0%, rgba(${gradientTop}, 1) 100%)`
  }
  return ''
})

const loadImage = async () => {
  const cachedPhoto = sessionStorage.getItem(state.organization.logo)
  if (cachedPhoto) {
    return cachedPhoto
  }
  try {
    const result = await imagesStore.getImage(state.organization.photoStorage, state.organization.logo)
    if (result) {
      sessionStorage.setItem(state.organization.logo, result)
    }
    return result
  } catch (error) {
    return ''
  }
}

watch(() => state.organization.logo, (newValue) => {
  if (newValue.length > 0) {
    loadImage().then(result => { imageSrc.value = result || '' })
  }
})
</script>

<style>
.org-info-wrapper {
  display: flex;
  align-items: center;
}

.org-info-plan-icon-wrapper {
  position: relative;
}

.org-info-plan-ellipse {
  border-radius: 50%;
  content: '';
  position: absolute;
  bottom: 0;
  left: -170%;
  transform: translateX(-50%);
  width: 6.3rem;
  height: 6.3rem;
  background-color: #FFFFFF52;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  z-index: 0;
}

.org-info-plan, .org-info-plan-test {
  padding: 0.36vw 1.41vw;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  margin-right: 1.56vw;
  overflow: hidden;
}

.org-info-plan {
  gap: 0.5rem;
}

.org-info-empty-img {
  background-color: #D9D9D9;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  margin-right: 0.78vw;
  width: 2.08vw;
  height: 2.08vw;
}

.org-info-plan-name {
  font: 0.78vw 'RedHatText-Display', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.org-info-org-name {
  font: 1.04vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.org-info-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.08vw;
  height: 2.08vw;
  border-radius: 2.5rem;
  margin-right: 0.78vw;
  background-size: cover;
  overflow: hidden;
}
.org-info-img img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 850px) {
  .org-info-plan, .org-info-plan-test {
    padding: 0.8vw 3vw;
    margin-right: 4vw;
  }
  .org-info-plan-name {
    font-size: 2vw;
  }

  .org-info-org-name {
    font-size: 3vw;
  }
  .org-info-img {
    width: 6vw;
    height: 6vw;
    margin-right: 2vw;
  }
}
</style>
