<template>
    <transition name="udialog-fade">
      <div class="udialog-overlay" v-if="true">
        <div class="udialog">
            <div class="add-universal-header">
                <span class="add-universal-header-text">
                    {{ props.id != '' ? 'Редактировать' : 'Добавить' }} {{ props.type === 'departments' ? 'отдел' : 'должность'}}
                </span>
                <img :src="cancel" class="button-click" @click="emits('dismiss')">
            </div>
            <div class="add-universal-body">
                <div class="add-universal-body-input-wrapper">
                    <span class="add-universal-body-text">
                        Название {{ props.type === 'departments' ? 'отдела' : 'должности'}}
                    </span>
                    <input class="add-universal-input" v-model="state.name">
                    <div v-if="props.id != ''">
                      <span class="add-universal-body-text">Сотрудники</span>
                      <div class="add-universal-persons" >
                        <u-person-row v-for="person in state.persons"
                          :key="person.id" :person="person"
                          :imgSrc="state.images[person.photo]?.imageSrc?? ''"
                          :posName="state.positions.find(item => item.id === person.posArr[0])?.name"
                          :isDel="true"
                          @del-person="emits('del-person', person.id)"
                        />
                      </div>
                      <div class="add-universal-add-person button-click"
                        @click="emits('open-choose-person')"
                      >
                        <img :src="employers">
                        <span>Выбрать</span>
                      </div>
                    </div>
                </div>
                <div class="add-universal-actions">
                    <div class="add-universal-actions-btn button-click"
                      id="ok-btn" @click="saveData()"
                    >
                        <span class="add-universal-actions-btn-text">Сохранить</span>
                    </div>
                    <div class="add-universal-actions-btn button-click" id="cancel-btn"
                        @click="emits('dismiss')"
                    >
                        <span class="add-universal-actions-btn-text">Отмена</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </transition>
</template>

<script setup lang="ts">
import { reactive, PropType, onBeforeMount, watch } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'
import employers from '@/assets/icon/org_item/employers.svg'
import { Employee, Positions, Organization } from '@/models'
import { Photo } from '@/classes'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { v4 as uuidv4 } from 'uuid'
import { eventBus } from '@/eventBus'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  text: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  persons: {
    type: Array as PropType<Employee[]>,
    default: () => []
  }
})

interface ImagesState {
  [key: string]: Photo;
}

const state = reactive({
  name: props.text,
  organization: {} as Organization,
  persons: [] as Employee[],
  positions: [] as Positions,
  images: reactive({} as ImagesState),
  toRemove: []
})

const emits = defineEmits(['dismiss', 'open-choose-person', 'save', 'del-person'])
const imagesStore = useImagesYarosStore()

onBeforeMount(() => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  getPersons()
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]')
})

function getPersons () {
  const personsTmp = JSON.parse(sessionStorage.getItem('persons') || '[]') as Employee[]
  state.persons = personsTmp.filter(item => {
    if (props.type === 'departments' && item.depArr.includes(props.id)) {
      return true
    } else if (props.type === 'positions' && item.posArr.includes(props.id)) {
      return true
    }
    return false
  })
}

watch(() => state.persons, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length > 0) {
    loadingImages()
  }
}, { deep: true })

async function loadingImages () {
  for (const person of state.persons) {
    if (person.photo && !(person.photo in state.images)) {
      let photo = new Photo()
      const photoId = person.photo
      try {
        const cachedPhoto = sessionStorage.getItem(person.photo)
        if (cachedPhoto) {
          photo = new Photo(cachedPhoto, false, false)
        } else {
          const result = await imagesStore.getImage(state.organization.fixStorage, photoId)
          if (result) {
            sessionStorage.setItem(person.photo, result)
            photo = new Photo(result, false, false)
          }
        }
      } catch (error) {
        console.error(`Failed to load image ${photoId}:`, error)
        photo = new Photo('', false, true)
      } finally {
        state.images = { ...state.images, ...{ [photoId]: photo } }
      }
    }
  }
}

function saveData () {
  const actType = props.id !== '' ? 'patch' : 'post'
  if (actType === 'post' && state.name.length === 0) {
    return
  }
  const data = {
    type: actType,
    data: {
      id: props.id !== '' ? props.id !== '' : uuidv4(),
      name: state.name
    }
  }
  emits('save', data)
}

eventBus.on('updatePersons', () => {
  getPersons()
})
</script>

<style scoped>
.udialog-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.udialog {
  width: 22.7vw;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 1.5rem 2rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-universal-body {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  margin-top: 2.78vh;
}

.add-universal-header {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-universal-actions {
  display: flex;
  gap: 0.799vw;
  margin-top: 4.629vh;
}

.add-universal-header-text {
  font: 700 2.04vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.add-universal-body-text {
  font: 500 1.39vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.add-universal-actions-btn-text {
  font: 700 1.49vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

#ok-btn {
  background-color: #042447;
}

#cancel-btn {
  background-color: #FFFFFF1A;
}

.add-universal-actions-btn {
  width: 8.3334vw;
  height: 4.7223vh;
  border-radius: 5vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-universal-input {
  height: 2.60vw;
  align-self: center;
  border-radius: 1.4rem;
  padding-left: 1.30vw;
  padding-right: 1.30vw;
  background-color: #2D4B6B;
  box-shadow: none;
  outline: none;
  border: none;
  flex-grow: 1;
  font: 0.78vw 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  width: -webkit-fill-available;
}

.add-universal-body-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.39vh;
}

.add-universal-add-person {
  display: flex;
  font: 0.78vw 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  gap: 0.521vw;
  background-color: #042447;
  width: max-content;
  padding: 0.926vh 0.782vw;
  border-radius: 5vw;
  margin-top: 1.39vh;
}

.add-universal-persons {
  display: flex;
  flex-wrap: wrap;
  gap: 1.39vh;
  padding-top: 1.39vh;
}
</style>
