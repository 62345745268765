<template>
    <div class="fix-rep-wrapper">
        <div class="report-wrapper">
            <div class="fix-rep-left">
                <div class="fix-rep-legend">
                    <span class="fix-rep-legend-text fix-report-timestamp">
                        Период
                    </span>
                    <div class="fix-rep-legend-block org-dep"
                      @click="filterByArray(state.departments, 'department')"
                    >
                      <span>
                        {{
                          state.departments.find(dep => dep.id === state.filterParams.department)?.name ?? 'Подразделения'
                        }}
                      </span>
                    </div>
                    <div class="fix-rep-legend-block org-pos"
                      @click="filterByArray(state.positions, 'position')"
                    >
                        <span>Физическое лицо</span>
                        <span>
                          {{
                            state.positions.find(pos => pos.id === state.filterParams.position)?.name ?? 'Должность'
                          }}
                        </span>
                    </div>
                    <div class="fix-rep-legend-block act-type button-click" @click="actionTypeSort">
                        <span>{{ state.actionLabel }}</span>
                        <span>Время</span>
                    </div>
                </div>
                <div v-if="!state.dataLoaded && state.reportData.length === 0">
                    <div class="fix-rep-loading">
                        <u-spinner />
                        <span>Загружаем данные</span>
                    </div>
                </div>
                <div v-if="state.reportData.length > 0" class="fix-report">
                  <div v-for="(item, index) in filteredData" :key="index">
                    <div :class="['fix-rep-row-position', { 'fix-rep-row-margin-top': index !== 0 }]"
                      @click="rowClick(index, item.photo)"
                      v-show="state.actionSortArg === 'all' || state.actionSortArg === item.type">
                      <div :class="['fix-rep-row fix-report-text button-click', { 'fix-rep-row-active': index === state.rowIndex }]">
                        <span class="fix-report-timestamp">{{ unitTimestampConvert(item.time) }}</span>
                        <div class="fix-report-row">
                          <span class="fix-report-row-dep"
                            v-if="state.departments.find(org => org.id === item.depId)?.name">
                            {{ state.departments.find(org => org.id === item.depId)?.name }}
                          </span>
                        </div>
                        <div class="fix-report-row org-pos org-pos-row">
                          <span class="fix-report-row-org">{{ item.perName }}</span>
                          <span class="fix-report-row-dep"
                            v-if="state.positions.find(pos => pos.id === item.posId)?.name">
                            {{ state.positions.find(pos => pos.id === item.posId)?.name }}
                          </span>
                        </div>
                        <div class="fix-report-row act-type act-type-row">
                          <span class="fix-report-row-org">{{ typeActMap[item.type as ActionType] }}</span>
                          <span class="fix-report-row-dep">{{ unitTimestampConvert(item.time, 'time') }}</span>
                        </div>
                      </div>
                      <div v-if="hasFilteredData" class="row-delimeter"></div>
                    </div>
                  </div>
                  <div v-if=!hasFilteredData class="fix-empty-after-filter">
                    <span>Нет данных, соответствующих выбранным фильтрам</span>
                  </div>
                </div>
                <div class="fix-rep-loading fix-rep-empty button-click" @click="loadPrevMonth()" v-else>
                    <span>{{ `Нет данных за период ${state.periodStr}` }}</span>
                    <span>{{ `Нажмите для загрузки ${state.prevMonth}` }}</span>
                </div>
                <div v-if="state.reportData.length > 0 && state.dataLoaded"
                    class="fix-rep-loading fix-rep-empty button-click"
                    @click="loadPrevMonth()"
                    >
                    <span>{{ `Нажмите для загрузки ${state.prevMonth}` }}</span>
                </div>
                <div v-else-if="!state.dataLoaded && state.reportData.length > 0">
                    <div class="fix-rep-loading">
                        <u-spinner />
                        <span>Загружаем данные</span>
                    </div>
                </div>
            </div>
            <div class="fix-rep-right">
                <div class="fix-rep-photo-block">
                    <div class="fix-rep-photo-block-label">
                        <span class="">Фотофиксация</span>
                    </div>
                    <div class="fix-rep-photo" v-if="state.reportData.length > 0 && hasFilteredData">
                        <div v-if="state.photo.isError || state.photo.isLoading">
                            <u-spinner v-show="state.photo.isLoading" />
                            <div class="fix-rep-photo-error">
                                <span v-show="state.photo.isError">
                                    Произошла ошибка при загрузке фото
                                </span>
                            </div>
                        </div>
                        <img v-else class="fix-rep-photo-image"
                            :src="`data:image/jpeg;base64, ${state.photo.imageSrc}`"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <u-filters v-if="state.showFilter"
      @close="state.showFilter = false"
      @filter-set="filterSet"
      :settedFilters="state.filterParams"
    />
</template>

<script setup lang="ts">
import { reactive, onBeforeMount, watch, computed } from 'vue'
import {
  unitTimestampConvert,
  getMonthStartEnd,
  getUnixTimestamps,
  convertToSubFilterMap,
  exportToExcel,
  ExcelData
} from '@/globalFunctions'
import {
  Organization,
  Departments,
  Positions,
  Fix,
  Employee,
  SubFilterMap,
  Universal
} from '@/models'
import { Photo } from '@/classes'
import { useFixesStore } from '@/stores/fixes'
import { useCookesStore } from '@/stores/cookies'
import { eventBus } from '@/eventBus'
import { DatesMap } from '@/components/widgets/UFilters.vue'
import { useImagesYarosStore } from '@/stores/imagesYaros'

const state = reactive({
  reportData: [] as Fix[],
  orgName: '',
  organization: {} as Organization,
  departments: [] as Departments,
  positions: [] as Positions,
  imageRef: '',
  rowIndex: -1,
  actionLabel: 'Приход/Уход',
  actionSortArg: 'all',
  dataLoaded: false,
  periodStr: '',
  prevMonth: '',
  orgId: '',
  screenWidth: 0,
  prevDate: [] as Array<number>,
  isLoading: false,
  persons: [] as Employee[],
  showFilter: false,
  filterParams: {} as SubFilterMap,
  daysTimestamps: {} as DatesMap,
  filteredData: [] as ExcelData[],
  photo: new Photo(),
  filteredDepartment: ''
})

const imagesStore = useImagesYarosStore()
type ActionType = 'arrive' | 'leave'

const imageSrc: Map<string, Photo> = new Map<string, Photo>()

const typeActMap: Record<ActionType, string> = {
  arrive: 'Приход',
  leave: 'Уход'
}

const fixes = useFixesStore()
const cookies = useCookesStore()

eventBus.on('filter', (obj) => {
  const validObj = convertToSubFilterMap(obj)
  if (validObj !== null) {
    state.filterParams = validObj
    rowClick(0, filteredData.value[0]?.photo)
  }
})

eventBus.on('reloadReport', async () => {
  await reloadFix()
})

eventBus.on('excelExport', () => {
  prepareDataForExport()
  exportToExcel(state.filteredData, 'fixes')
})

const filteredData = computed(() => {
  const filteredFixes = state.reportData
    .filter(item => filter(item))
    .sort((a, b) => b.time - a.time)
  return filteredFixes
})

const hasFilteredData = computed(() => {
  return filteredData.value.length > 0
})

function prepareDataForExport () {
  state.filteredData = state.reportData
    .filter(filter)
    .map(item => {
      return {
        Период: item.time ? unitTimestampConvert(item.time) : '',
        Подразделение: state.departments.find(dep => dep.id === item.depId)?.name || '',
        'Физическое лицо': item.perName,
        Должность: state.positions.find(pos => pos.id === item.posId)?.name || '',
        'Приход / Уход': typeActMap[item.type as ActionType],
        Время: item.time ? unitTimestampConvert(item.time, 'time') : ''
      }
    })
}

eventBus.on('showFilter', () => {
  state.showFilter = true
})

onBeforeMount(async () => {
  imageSrc.set('null', new Photo())
  state.screenWidth = window.innerWidth
  await loadingData()
  state.daysTimestamps = getUnixTimestamps()
})

watch(() => state.prevDate, (newValue) => {
  if (newValue.length > 0) {
    state.prevMonth = `${newValue[0].toString().padStart(2, '0')}.${newValue[1]}`
  }
})

function filterUniquePhotos (data: Fix[]): Fix[] {
  const seenPhotos = new Set<string>()
  return data.filter(item => {
    if (seenPhotos.has(item.photo)) {
      return false
    }
    seenPhotos.add(item.photo)
    return true
  })
}

async function loadingData () {
  state.reportData = JSON.parse(sessionStorage.getItem('fixes') || '[]')
  state.reportData = filterUniquePhotos(state.reportData)
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.orgName = state.organization.name
  const departmentsData = sessionStorage.getItem('departments')
  if (departmentsData) {
    state.departments = JSON.parse(departmentsData)
  }
  const positionsData = sessionStorage.getItem('positions')
  if (positionsData) {
    state.positions = JSON.parse(positionsData)
  }
  const persons = sessionStorage.getItem('persons')
  if (persons) {
    state.persons = JSON.parse(persons)
  }
  preparePeriod()
  state.dataLoaded = true
  if (state.reportData.length > 0) {
    rowClick(0, filteredData.value[0]?.photo)
  }
}

function preparePeriod () {
  const currentDate = new Date()
  const currMonth = currentDate.getMonth()
  const currYear = currentDate.getFullYear()
  const prevMonth = currMonth === 0 ? 11 : currMonth - 1
  const prevYear = currMonth === 0 ? currYear - 1 : currYear
  state.prevDate = [prevMonth + 1, prevYear]
  state.periodStr = `${prevMonth.toString().padStart(2, '0')}.${prevYear}-${(currMonth + 1).toString().padStart(2, '0')}.${currYear}`
}

async function rowClick (index: number, photoId: string) {
  if (photoId && state.imageRef !== photoId) {
    state.photo = new Photo()
    state.rowIndex = index
    state.imageRef = photoId

    if (!imageSrc.has(photoId)) {
      let result = ''
      try {
        let photo = new Photo()
        result = await imagesStore.getImage(state.organization.fixStorage, photoId, 200)
        if (result) {
          photo = new Photo(result, false, false)
        }
        imageSrc.set(photoId, photo)
        state.photo = photo
      } catch (error) {
        const errorPhoto = new Photo(result, false, result === '')
        imageSrc.set(photoId, errorPhoto)
        state.photo = errorPhoto
      }
    } else {
      state.photo = imageSrc.get(photoId) || new Photo()
    }
  }
}

function actionTypeSort () {
  const steps = ['all', 'arrive', 'leave']
  const stepsLen = steps.length
  const currStep = steps.findIndex(step => step === state.actionSortArg)
  if (stepsLen - 1 === currStep) {
    state.actionSortArg = steps[0]
    state.actionLabel = 'Приход/Уход'
  } else {
    state.actionSortArg = steps[currStep + 1]
    state.actionLabel = typeActMap[state.actionSortArg as ActionType]
  }
}

async function loadPrevMonth () {
  state.dataLoaded = false
  if (state.orgId === '') {
    await cookies.getCookie()
    state.orgId = cookies.getCookies.orgId
  }
  const prevMonth = state.prevDate[0]
  const year = state.prevDate[1]
  const prevMonthTimeStamp = getMonthStartEnd(prevMonth, year)
  await fixes.getFixesAction(state.orgId, null, prevMonthTimeStamp[0], prevMonthTimeStamp[1])
  state.reportData = [...state.reportData, ...fixes.getFixes]
  if (prevMonth - 1 === 0) {
    state.prevDate = [12, year - 1]
  } else {
    state.prevDate = [prevMonth - 1, year]
  }
  state.dataLoaded = true
}

async function reloadFix () {
  state.isLoading = false
  state.imageRef = ''
  const timestamps = getMonthStartEnd()
  if (state.orgId === '') {
    await cookies.getCookie()
    state.orgId = cookies.getCookies.orgId
  }
  await fixes.getFixesAction(state.orgId, null, timestamps[0], timestamps[1])
  sessionStorage.setItem('fixes', JSON.stringify(fixes.getFixes))
  await loadingData()
}

function filterSet (obj: SubFilterMap) {
  state.filterParams = obj
}

function filter (item: Fix) {
  if (Object.keys(state.filterParams).length === 0) {
    return true
  }
  const args = Object.keys(state.filterParams)
  if (args.includes('department') && state.filterParams.department.length > 0 &&
    item.depId !== state.filterParams.department) {
    return false
  }
  if (args.includes('position') && state.filterParams.position.length > 0 &&
    item.posId !== state.filterParams.position) {
    return false
  }
  if (args.includes('person') && state.filterParams.person.length > 0 &&
    item.perId !== state.filterParams.person) {
    return false
  }
  if (args.includes('date') && state.filterParams.date.length > 0) {
    let datesDiap : Array<number>
    if (typeof state.filterParams.date === 'string') {
      datesDiap = state.daysTimestamps[state.filterParams.date]
    } else {
      datesDiap = state.filterParams.date
    }
    if (datesDiap[0] > item.time ||
        datesDiap[1] < item.time) {
      return false
    }
  }
  return true
}

function filterByArray (array: Array<Universal>, type: string) {
  if (typeof array === 'string') return
  const arrLen = array.length
  if (arrLen === 0) return
  if (!Object.keys(state.filterParams).includes(type)) {
    state.filterParams = { ...state.filterParams, ...{ [type]: array[0].id } }
  } else {
    const ind = array.findIndex((dep) => dep.id === state.filterParams[type])
    if (ind + 1 === arrLen) {
      delete state.filterParams[type]
    } else {
      state.filterParams[type] = array[ind + 1].id
    }
  }
  eventBus.emit('filter', state.filterParams)
}
</script>

<style>
.fix-rep-wrapper {
    height: 100%;
}

.fix-rep-left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 83vh;
}

.fix-rep-right {
    display: flex;
    flex-direction: column;
    width: 23.5rem;
}

.fix-rep-row {
    display: flex;
    width: 40rem;
    gap: 1rem;
}

.fix-rep-setting-block {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.fix-rep-back-block {
    font-size: 1.6rem;
}

.fix-rep-setting-block {
    font-size: 1rem;
}

.fix-rep-legend,
.fix-rep-photo-block-label,
.fix-rep-loading {
    font-family: 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.fix-rep-setting-block-choose-days {
    background-color: #FFFFFF0D;
    padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    border-radius: 1rem;
    font: 1rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    display: flex;
    align-items: center;
}

.fix-rep-legend {
    gap: 1rem;
}

.fix-rep-legend,
.fix-rep-photo-block-label {
    display: flex;
    background-color: #2D4B6B;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
    border-radius: 1.2rem;
    align-items: center;
    min-height: 3.1rem;
}

.fix-rep-legend-text,
.fix-rep-loading {
    font-size: 0.8rem;
}

.fix-rep-legend-block {
    display: flex;
    height: 100%;
    flex-direction: column;
    font-size: 0.6rem;
    background-color: #FFFFFF0D;
    padding: 0 0 0 0.6rem;
    border-radius: 0.8rem;
    gap: 0.4rem;
    justify-content: center;
}

.report-wrapper {
    display: flex;
    gap: 2rem;
    width: 80vw;
}

.fix-rep-photo-block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.fix-rep-photo-block-label {
    font-size: 1rem;
    justify-content: center;
}

.fix-report {
    background-color: #2D4B6B;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
    border-radius: 1.2rem;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: -webkit-fill-available;
    &::-webkit-scrollbar {
        display: none;
    }
}

.fix-report-text {
    font: 0.565rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.fix-report-timestamp {
    width: 5.8rem;
}

.fix-report-row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 16.3rem;
}

.org-dep {
    width: 15.7rem;
}

.fix-report-row-org {
    margin-left: 0.8rem;
}

.fix-report-row-dep {
    background-color: #FFFFFF0D;
    padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    border-radius: 0.4rem;
}

.org-pos {
    width: 8.7rem;
}

.org-pos-row {
    width: 9.3rem;
}

.act-type {
    width: 5.2rem;
}

.act-type-row {
    width: 5.8rem;
}

.row-delimeter {
    border-bottom: 1px solid #FFFFFF1A;
}

.fix-rep-row-position {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fix-rep-row-margin-top {
    margin-top: 1rem;
}

.fix-rep-photo {
    border-radius: 1.2rem;
}

.fix-rep-row-active {
    background-color: #FFFFFF0D;
    border-radius: 1rem;
    padding: 0.5rem 1.2rem 0.5rem 1.2rem;
    box-sizing: border-box;
}

.fix-rep-loading {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF0D;
    border-radius: 1rem;
    padding-bottom: 1.5rem;
    align-items: center;
}

.fix-rep-empty {
    padding-top: 1.5rem;
}

.fix-rep-photo {
    background-color: #2D4B6B;
    display: flex;
    width: 100%;
    height: 40.75vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.fix-rep-photo-error {
    font: 0.8rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-error);
}

.fix-empty-after-filter {
  display: flex;
  width: 100%;
  font: 0.8rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  align-items: center;
  justify-content: center;
}

.fix-rep-photo-image {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border-radius: 1rem;
}
</style>
