<template>
  <transition name="udialog-fade">
    <div class="udialog-overlay" v-if="true">
      <u-photo-frame v-if="state.showPhotoFrame"
        @dismiss="state.showPhotoFrame = false"
        @getted-photo="getPhoto"
      />
      <div v-show="!state.showPhotoFrame" class="udialog">
        <div class="add-person-header">
          <span class="add-person-header-text">
            {{ props.id != '' ? 'Редактировать' : 'Добавить' }} сотрудника
          </span>
          <img :src="cancel" class="button-click" @click="emits('dismiss')">
        </div>
        <div class="person-main">
          <div class="person-name-photo">
            <div>
              <div class="person-add-photo-wrapper button-click"
                @click="state.popupIsOpen = true"
              >
                <img class="person-add-photo-icon"
                  :class="{'person-add-photo-base64': state.photo !== ''}"
                  :src="getImage()"
                >
              </div>
              <div class="person-popup" v-if="state.popupIsOpen"
              >
                <div class="person-popup-row button-click"
                  @click="popupAction('red')"
                >
                  <img :src="editIcon">
                  <span class="person-popup-text">Редактировать</span>
                </div>
                <!-- <div class="person-popup-row button-click"
                  @click="console.log('action')"
                >
                  <img :src="deleteIcon">
                  <span class="person-popup-text">Удалить</span>
                </div> -->
              </div>
            </div>
            <div class="person-universal person-name">
              <span class="person-label">Имя</span>
              <input class="person-name-input" type="text"
                v-model="state.person.name"
                placeholder="Введите имя"
              >
            </div>
          </div>
          <div class="person-universal-wrapper">
            <div class="person-universal">
              <span class="person-label">Отдел</span>
              <div class="person-universal-btn"
                @click="openUniversal('departments')"
              >
                <img :src="depIcon" class="person-universal-icon">
                <span>Выбрать</span>
              </div>
            </div>
            <div class="person-universal-list"
              v-if="state.person.depArr && state.person.depArr.length > 0"
            >
              <u-universal-row v-for="dep in state.person.depArr ?? []"
                :key="dep" :item="state.departments.find(item => item.id === dep)"
                @del-universal="state.person.depArr = state.person.depArr.filter(id => id !== dep)"
              />
            </div>
            <div class="person-universal">
              <span class="person-label">Должность</span>
              <div class="person-universal-btn"
                @click="openUniversal('positions')"
              >
                <img :src="posIcon" class="person-universal-icon">
                <span>Выбрать</span>
              </div>
            </div>
            <div class="person-universal-list"
              v-if="state.person.posArr && state.person.posArr.length > 0"
            >
              <u-universal-row v-for="pos in state.person.posArr ?? []"
                :key="pos" :item="state.positions.find(item => item.id === pos)"
                @del-universal="state.person.posArr = state.person.posArr.filter(id => id !== pos)"
              />
            </div>
          </div>
          <div class="personal-timesets-wrapper">
            <div class="personal-timesets">
              <span class="person-label">Начало рабочего дня</span>
              <VueDatePicker
                time-picker
                format="HH:mm"
                v-model="startTime"
                locale="ru"
                cancel-text="Отмена"
                select-text="Ок"
                class="vue-datepicker personal-timepicker"
                >
                <template #clear-icon="{ open }">
                  <div class="input-slot-wrapper">
                    <img class="input-slot-image" src="@/assets/icon/time.svg" @click="open" />
                  </div>
                </template>
              </VueDatePicker>
            </div>
            <div class="personal-timesets">
              <span class="person-label">Конец рабочего дня</span>
              <VueDatePicker
                time-picker
                format="HH:mm"
                v-model="endTime"
                locale="ru"
                cancel-text="Отмена"
                select-text="Ок"
                class="vue-datepicker personal-timepicker"
                >
                <template #clear-icon="{ open }">
                  <div class="input-slot-wrapper">
                    <img class="input-slot-image" src="@/assets/icon/time.svg" @click="open" />
                  </div>
                </template>
              </VueDatePicker>
            </div>
          </div>
          <div class="personal-timesets-wrapper">
            <div class="personal-timesets">
              <span class="person-label">Возможные мин. опоздания</span>
              <VueDatePicker
                time-picker
                :format="lateTime.hours > 0 ? 'HH ч. mm м.' : 'm м.'"
                v-model="lateTime"
                locale="ru"
                cancel-text="Отмена"
                select-text="Ок"
                class="vue-datepicker personal-timepicker"
                >
                <template #clear-icon>
                  <div class="input-slot-wrapper">
                    <img class="input-slot-inc"
                      src="@/assets/icon/vert-count-plus.svg"
                      @click.stop="calcTime('lateTime', 'inc')"
                    />
                    <img class="input-slot-dec"
                      src="@/assets/icon/vert-count-minus.svg"
                      @click.stop="calcTime('lateTime', 'dec')"
                    />
                  </div>
                </template>
              </VueDatePicker>
            </div>
            <div class="personal-timesets">
              <span class="person-label">Возможные мин. раннего ухода</span>
              <VueDatePicker
                time-picker
                :format="leaveTime.hours > 0 ? 'HH ч. mm м.' : 'm м.'"
                v-model="leaveTime"
                locale="ru"
                cancel-text="Отмена"
                select-text="Ок"
                class="vue-datepicker personal-timepicker"
                >
                <template #clear-icon>
                  <div class="input-slot-wrapper">
                    <img class="input-slot-inc"
                      src="@/assets/icon/vert-count-plus.svg"
                      @click.stop="calcTime('leaveTime', 'inc')"
                    />
                    <img class="input-slot-dec"
                      src="@/assets/icon/vert-count-minus.svg"
                      @click.stop="calcTime('leaveTime', 'dec')"
                    />
                  </div>
                </template>
              </VueDatePicker>
            </div>
          </div>
        </div>
        <div class="person-actions">
          <div class="person-actions-btn button-click" id="ok-btn"
            @click="savePerson()"
          >
            <span class="person-actions-btn-text">Сохранить</span>
          </div>
          <div class="person-actions-btn button-click" id="cancel-btn"
            @click="emits('dismiss')"
          >
            <span class="person-actions-btn-text">Отмена</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <u-choose-photo-type v-if="state.photoTypeIsOpen"
    @dismiss="state.photoTypeIsOpen = false"
    @result="choosePhotoType"
    @getted-photo="getPhoto"
  />
  <u-save-photo-dialog v-if="state.savePhotoDialogIsOpen"
    :photo="state.photoTmp"
    @save="saveTakedPicture"
    @dismiss="takePictureAgain()"
  />
  <u-universal-selector v-if="universal.universalIsOpen"
    :type="universal.type"
    :dataArray="universal.type === 'departments' ? state.departments : state.positions"
    :activeArray="universal.activeArray"
    @dismiss="universal.universalIsOpen = false"
    @save="saveUniversal"
  />
</template>

<script setup lang="ts">
import { Employee, Organization, Universal } from '@/models'
import { reactive, onBeforeMount, ref, PropType, watch } from 'vue'
import cancel from '@/assets/icon/cancel-dialog.svg'
import depIcon from '@/assets/icon/departments.svg'
import posIcon from '@/assets/icon/positions.svg'
import addPhoto from '@/assets/icon/person_add_photo.svg'
import editIcon from '@/assets/icon/org_item/edit.svg'
// import deleteIcon from '@/assets/icon/org_item/delete.svg'
import { UniversalResult } from '@/components/widgets/UUniversalSelector.vue'
import { v4 as uuidv4 } from 'uuid'
import {
  getTimeStampUTC,
  base64ToBlob,
  getHoursMinutesFromTimestamp
} from '@/globalFunctions'
import { useAzureStore } from '@/stores/azure'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { useEmploysStore } from '@/stores/employs'
import { cloneDeep, isEqual } from 'lodash'

const azureStore = useAzureStore()
const imageStore = useImagesYarosStore()
const personStore = useEmploysStore()

const state = reactive({
  person: {} as Employee,
  organization: {} as Organization,
  positions: [] as Universal[],
  departments: [] as Universal[],
  photoTmp: '',
  photo: '',
  showPhotoFrame: false,
  photoTypeIsOpen: false,
  getFileOpen: false,
  savePhotoDialogIsOpen: false,
  popupIsOpen: false,
  photoChanged: false
})

const universal = reactive({
  universalIsOpen: false,
  type: '',
  activeArray: [] as string[]
})

const redactData = reactive({
  data: {} as Employee
})

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  person: {
    type: Object as PropType<Employee>,
    default: null
  }
})

const emits = defineEmits(['dismiss', 'show-loader', 'show-dialog', 'success-updated'])
const startTime = ref({ hours: 9, minutes: 0 })
const endTime = ref({ hours: 18, minutes: 0 })
const lateTime = ref({ hours: 0, minutes: 0 })
const leaveTime = ref({ hours: 0, minutes: 0 })

onBeforeMount(async () => {
  if (props.person) {
    state.person = cloneDeep(props.person)
    const startTimeTmp = getHoursMinutesFromTimestamp(state.person.arrivalTime)
    startTime.value = { hours: startTimeTmp.hours, minutes: startTimeTmp.minutes }
    const endTimeTmp = getHoursMinutesFromTimestamp(state.person.leaveTime)
    endTime.value = { hours: endTimeTmp.hours, minutes: endTimeTmp.minutes }
    const lateTimeTmp = state.person.lateTime
    lateTime.value = { hours: Math.floor(lateTimeTmp / 60), minutes: lateTimeTmp % 60 }
    const leaveTimeTmp = state.person.earlyLeave
    leaveTime.value = { hours: Math.floor(leaveTimeTmp / 60), minutes: leaveTimeTmp % 60 }
  } else {
    state.person.orgId = state.organization.orgId
  }
  if (props.person && props.person.photo && props.person.photo !== '') {
    const cachedPhoto = sessionStorage.getItem(state.person.photo)
    if (cachedPhoto) {
      state.photo = `data:image/jpeg;base64, ${cachedPhoto}`
    } else {
      const result = await imageStore.getImage(state.organization.photoStorage, state.person.photo)
      if (result) {
        sessionStorage.setItem(state.person.photo, result)
      }
      state.photo = result ? `data:image/jpeg;base64, ${result}` : ''
    }
  }
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]')
  state.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
})

function popupAction (action: string) {
  if (action === 'red') {
    state.popupIsOpen = false
    state.photoTypeIsOpen = true
  }
}

function getPhoto (base64: string) {
  state.photoTmp = base64
  state.showPhotoFrame = false
  state.savePhotoDialogIsOpen = true
}

function takePictureAgain () {
  state.savePhotoDialogIsOpen = false
  state.photoTypeIsOpen = true
}

function calcTime (timeType: string, actionType: string) {
  let timeObject
  if (timeType === 'lateTime') {
    timeObject = lateTime
  } else if (timeType === 'leaveTime') {
    timeObject = leaveTime
  } else {
    return
  }

  let minutesTotal = timeObject.value.hours * 60 + timeObject.value.minutes
  if (actionType === 'inc') {
    minutesTotal += 1
  } else if (actionType === 'dec') {
    minutesTotal -= 1
  }

  if (minutesTotal < 0) {
    minutesTotal = 0
  }

  timeObject.value = { hours: Math.floor(minutesTotal / 60), minutes: minutesTotal % 60 }
}

function choosePhotoType (type: string) {
  if (type === 'photo') {
    state.showPhotoFrame = true
  } else {
    state.getFileOpen = true
  }
  state.photoTypeIsOpen = false
}

function saveTakedPicture () {
  state.photo = state.photoTmp
  state.savePhotoDialogIsOpen = false
  state.photoChanged = true
}

function openUniversal (type: string) {
  universal.type = type
  const tmpArr = type === 'departments' ? state.person.depArr : state.person.posArr
  universal.activeArray = tmpArr ?? []
  universal.universalIsOpen = true
}

function saveUniversal (obj: UniversalResult) {
  const arrType = universal.type === 'departments' ? 'depArr' : 'posArr'
  const stringType = universal.type === 'departments' ? 'depId' : 'posOrg'
  universal.universalIsOpen = false
  if (obj.added.length > 0) {
    state.person[arrType] = [...state.person[arrType] ?? [], ...obj.added]
  }
  if (obj.removed.length > 0) {
    for (const id of obj.removed) {
      state.person[arrType] = state.person[arrType] ?? [].filter(item => item !== id)
    }
  }
  if (!state.person[stringType] || !state.person[arrType].includes(state.person[stringType])) {
    state.person[stringType] = state.person[arrType][0]
  }
}

function showDialog (header: string, message: string) {
  emits('show-dialog', { header, message })
}

async function savePerson () {
  if (!state.person.name || state.person.name === '') {
    showDialog('Ошибка', 'Укажите имя сотрудника.')
    return
  }
  if (!state.photo || state.photo === '') {
    showDialog('Ошибка', 'Необходимо сфотографировать сотрудника.')
    return
  }
  emits('show-loader')
  if (!props.person || props.person === null) {
    if (!state.person.id || state.person.id === '') {
      state.person.id = uuidv4()
    }
    const personResponse = await azureStore.addPersonCompanyAzure(
      state.organization.orgId,
      state.person.id,
      state.person.name
    )
    const azureId = personResponse?.personId
    if (!azureId) {
      showDialog('Ошибка', 'Упс! Что-то пошло не так!')
      return
    }
    const blob = await base64ToBlob(state.photo)
    const azurePhotoResponse = await azureStore.addPhotoPersonAzure(
      state.organization.orgId, azureId!, blob
    )
    const persistedFaceId = azurePhotoResponse?.persistedFaceId
    if (!persistedFaceId) {
      showDialog('Ошибка', 'Упс! Что-то пошло не так!')
      return
    }
    const imageId = await imageStore.postImageAction(blob)
    state.person.azureId = azureId!
    state.person.faceId = persistedFaceId!
    state.person.photo = imageId!
    const result = await personStore.registrationPerson(state.organization.orgId, state.person)
    if (result?.data.status === 'created') {
      await updPersonCache()
      const trainResult = await azureStore.trainGroupAzure(state.organization.orgId)
      if (trainResult !== 'success') {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
        return
      }
      showDialog('Успех', result?.data.message)
      emits('success-updated')
    } else {
      showDialog('Ошибка', result?.data.message ?? 'Упс! Что-то пошло не так!')
    }
  } else {
    for (const key of Object.keys(state.person)) {
      if (!['id', 'orgId'].includes(key)) {
        if (state.person[key as keyof typeof state.person] !==
          props.person[key as keyof typeof props.person]) {
          if (!['posArr', 'depArr'].includes(key)) {
            if (['arrivalTime', 'leaveTime'].includes(key)) {
              if (getHoursMinutesFromTimestamp(Number(state.person[key as keyof typeof state.person])) !==
                getHoursMinutesFromTimestamp(Number(props.person[key as keyof typeof props.person]))) {
                redactData.data = { ...redactData.data, ...{ [key]: state.person[key as keyof typeof state.person] } }
              }
            }
            redactData.data = { ...redactData.data, ...{ [key]: state.person[key as keyof typeof state.person] } }
          } else {
            if (!isEqual(state.person[key as keyof typeof state.person],
              props.person[key as keyof typeof props.person])) {
              redactData.data = { ...redactData.data, ...{ [key]: state.person[key as keyof typeof state.person] } }
            }
          }
        }
      }
    }
    if (state.photoChanged) {
      const blob = await base64ToBlob(state.photo)
      const azurePhotoResponse = await azureStore.addPhotoPersonAzure(
        state.organization.orgId, state.person.azureId, blob
      )
      const persistedFaceId = azurePhotoResponse?.persistedFaceId
      if (!persistedFaceId) {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
        return
      }
      const imageId = await imageStore.postImageAction(blob)
      if (!imageId) {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
        return
      }
      redactData.data = {
        ...redactData.data,
        ...{
          faceId: persistedFaceId!,
          photo: imageId!
        }
      }
    }
    if (Object.keys(redactData.data).length > 0) {
      redactData.data = { ...redactData.data, ...{ id: state.person.id } }
      const result = await personStore.patchPerson(state.organization.orgId, [redactData.data])
      if (result?.status === 'complete') {
        await updPersonCache()
        if (state.photoChanged) {
          const trainResult = await azureStore.trainGroupAzure(state.organization.orgId)
          if (trainResult !== 'success') {
            showDialog('Ошибка', 'Упс! Что-то пошло не так!')
            return
          }
        }
        showDialog('Успех', 'Данные успешно обновлены')
        emits('success-updated')
      } else {
        showDialog('Ошибка', 'Упс! Что-то пошло не так!')
      }
    }
  }
}

async function updPersonCache () {
  await personStore.getEmploysAction(state.organization.orgId)
  const persons = personStore.getEmploys
  sessionStorage.setItem('persons', JSON.stringify(persons))
}

function getImage () {
  return state.photo === '' ? addPhoto : state.photo
}

watch(() => startTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.arrivalTime = getTimeStampUTC(newValue.hours, newValue.minutes, state.organization.timezone)
  }
})

watch(() => endTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.leaveTime = getTimeStampUTC(newValue.hours, newValue.minutes, state.organization.timezone)
  }
})

watch(() => lateTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.lateTime = newValue.hours * 60 + newValue.minutes
  }
})

watch(() => leaveTime.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    state.person.earlyLeave = newValue.hours * 60 + newValue.minutes
  }
})
</script>

<style>
.udialog-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.udialog {
  width: 27.342vw;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 3.71vh 2.345vw;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.person-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: -webkit-fill-available;
  align-items: flex-start;
  gap: 2.32vh;
}

.person-name-input {
  height: 4.445vh;
  width: 18.234vw;
  border-radius: 3.8vh;
  padding-left: 1.30vw;
  padding-right: 1.30vw;
  background-color: #2D4B6B;
  box-shadow: none;
  outline: none;
  border: none;
  flex-grow: 1;
  font: 0.78vw 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.add-person-header {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.8vh;
}

.add-person-header-text {
  font: 700 2.04vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.person-universal-icon {
  height: 1.4vh;
}

.person-universal-btn {
  display: flex;
  gap: 0.926vh;
  background-color: #042447;
  border-radius: 13.89vh;
  padding: 0.903vh 0.834vw;
  font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  width: fit-content;
}

.person-universal-wrapper,
.personal-timesets-wrapper {
  display: flex;
  gap: 1.303vw;
}

.person-universal-wrapper {
  flex-direction: column;
}

.person-label {
  font: 400 1.389vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
  margin-bottom: 1.389vh;
}

.person-universal {
  display: flex;
  flex-direction: column;
}

.personal-timesets {
  display: flex;
  flex-direction: column;
}

.personal-timepicker {
  width: 10.417vw !important;
  --dp-font-weight: 400 !important;
  --dp-font-size: 1.389vh !important;
  --dp-line-height: 0 !important;
  --dp-padding: 1.389vh 1.5rem !important;
}

.input-slot-image {
  position: relative;
  right: 0.5vw;
}

.input-slot-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2vh;
  position: relative;
  right: 0.5vw;
  align-self: start;
}

.person-actions {
  display: flex;
  gap: 0.799vw;
  margin-top: 4.629vh;
  width: -webkit-fill-available;
}

.person-actions-btn {
  width: 8.3334vw;
  height: 4.7223vh;
  border-radius: 5vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.person-actions-btn-text {
  font: 700 1.49vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

#ok-btn {
  background-color: #042447;
}

#cancel-btn {
  background-color: #FFFFFF1A;
}

.person-name-photo {
  position: relative;
  display: flex;
  gap: 1.3021vw;
}

.person-add-photo-wrapper {
  background-color: var(--primary-font-color-a10);
  width: 7.871vh;
  height: 7.871vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.person-add-photo-icon {
  height: 2.315vh;
}

.person-name {
  width: 21.62vw !important;
}

.person-add-photo-base64 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.person-popup-row {
  display: flex;
  gap: 0.64vw;
  height: 4.63vh;
  width: 9.59vw;
  align-items: center;
  padding-left: 1.851vh;
}

.person-popup {
  display: flex;
  flex-direction: column;
  background-color: #2D4B6B;
  border-radius: 2.5vh;
  padding: 1.389vh 0.534vw;
  width: fit-content;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 9vh;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.15);
}

.person-popup-text {
  font: 400 1.386vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.person-universal-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5209vw;
}
</style>
