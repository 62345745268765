<template>
    <transition name="udialog-fade">
        <div class="udialog-overlay" v-if="true">
            <div class="choose-person-wrapper">
                <div class="choose-person-header">
                    <span class="choose-person-header-text">
                        Выберите сотрудников
                    </span>
                    <img :src="cancel" class="button-click" @click="emits('dismiss')">
                </div>
                <div class="choose-person-column">
                    <u-person-row v-for="person in state.persons" :key="person.id"
                        :person="person" :isChoosen="isChoosen(person)"
                        :posName="state.positions.find(item => item.id === person.posArr[0])?.name"
                        :imgSrc="state.images[person.photo]?.imageSrc?? ''"
                        @row-click="addOrRemove(person.id)"
                    />
                </div>
                <div class="choose-person-actions">
                    <div class="choose-person-actions-btn button-click" id="ok-btn"
                      @click="saveClick()"
                    >
                      <span class="choose-person-actions-btn-text">Сохранить</span>
                    </div>
                    <div class="choose-person-actions-btn button-click" id="cancel-btn"
                      @click="emits('dismiss')"
                    >
                      <span class="choose-person-actions-btn-text">Отмена</span>
                    </div>
                </div>
            </div>
        </div>
  </transition>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount, watch } from 'vue'
import { Employee, Organization, Positions } from '@/models'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { Photo } from '@/classes'
import cancel from '@/assets/icon/cancel-dialog.svg'
import { useEmploysStore } from '@/stores/employs'
import { eventBus } from '@/eventBus'

export interface ImagesState {
  [key: string]: Photo;
}

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
})

const state = reactive({
  organization: {} as Organization,
  persons: [] as Employee[],
  positions: [] as Positions,
  images: reactive({} as ImagesState),
  added: [] as string[],
  removed: [] as string[]
})

const imagesStore = useImagesYarosStore()
const employsStore = useEmploysStore()

const emits = defineEmits(['dismiss', 'show-loader', 'show-dialog'])

onBeforeMount(async () => {
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.positions = JSON.parse(sessionStorage.getItem('positions') || '[]')
})

watch(() => state.persons, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length > 0) {
    loadingImages()
  }
}, { deep: true })

async function loadingImages () {
  for (const person of state.persons) {
    if (person.photo && !(person.photo in state.images)) {
      let photo = new Photo()
      const photoId = person.photo
      try {
        const cachedPhoto = sessionStorage.getItem(person.photo)
        if (cachedPhoto) {
          photo = new Photo(cachedPhoto, false, false)
        } else {
          const result = await imagesStore.getImage(state.organization.fixStorage, photoId)
          if (result) {
            sessionStorage.setItem(person.photo, result)
            photo = new Photo(result, false, false)
          }
        }
      } catch (error) {
        console.error(`Failed to load image ${photoId}:`, error)
        photo = new Photo('', false, true)
      } finally {
        state.images = { ...state.images, ...{ [photoId]: photo } }
      }
    }
  }
}

function isChoosen (person: Employee) {
  let isActive = isChoosenInAction(person)
  if (!isActive && !state.removed.includes(person.id)) {
    if (props.type === 'positions' && person.posArr.includes(props.id)) {
      isActive = true
    } else if (props.type === 'departments' && person.depArr.includes(props.id)) {
      isActive = true
    }
  }
  return isActive
}

function isChoosenInAction (person: Employee) {
  return state.added.includes(person.id) && !state.removed.includes(person.id)
}

function addOrRemove (perId: string) {
  if (!state.removed.includes(perId)) {
    if (props.type === 'positions' &&
        state.persons.find(person => person.id === perId)?.posArr.includes(props.id)) {
      state.removed.push(perId)
      return
    } else if (props.type === 'departments' &&
        state.persons.find(person => person.id === perId)?.depArr.includes(props.id)) {
      state.removed.push(perId)
      return
    }
  }

  if (state.added.includes(perId)) {
    state.added = state.added.filter(item => item !== perId)
    state.removed.push(perId)
  } else if (state.removed.includes(perId)) {
    state.removed = state.removed.filter(item => item !== perId)
  } else {
    state.added.push(perId)
  }
}

async function saveClick () {
  const result = []
  const arrToPatch = props.type === 'departments' ? 'depArr' : 'posArr'
  const itemToPatch = props.type === 'departments' ? 'depId' : 'posOrg'
  for (const id of state.added) {
    result.push(
      {
        id,
        [arrToPatch]: [...state.persons.find(person => person.id === id)![arrToPatch], props.id],
        [itemToPatch]: props.id
      }
    )
  }
  for (const id of state.removed) {
    const tmpArr = state.persons.find(person => person.id === id)![arrToPatch].filter(item => item !== props.id)
    result.push(
      {
        id,
        [arrToPatch]: tmpArr,
        [itemToPatch]: tmpArr.length > 0 ? tmpArr[0] : ''
      }
    )
  }
  if (result.length > 0) {
    emits('show-loader')
    const patchResult = await employsStore.patchPerson(state.organization.orgId, result)
    if (patchResult!.status === 'complete') {
      await employsStore.getEmploysAction(state.organization.orgId)
      state.persons = employsStore.getEmploys
      sessionStorage.setItem('persons', JSON.stringify(state.persons))
      eventBus.emit('updatePersons')
      showDialog('Успех', 'Данные успешно обновлены', true)
      emits('dismiss')
    } else {
      showDialog('Ошибка', 'Упс! Что-то пошло не так!', false)
    }
  }
}

function showDialog (header: string, text: string, isSuccess: boolean) {
  emits('show-dialog', { header, text, isSuccess })
}
</script>

<style>
.choose-person-wrapper {
    background: linear-gradient(90deg, #042447 0%, #2D4B6B 100%);
    padding: 3.71vh 2.344vw;
    border-radius: 3.71vh;
    width: 20.834vw;
}

.choose-person-column {
    display: flex;
    flex-direction: column;
    gap: 0.925vh;
}

.choose-person-header {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.8vh;
}

.choose-person-header-text {
  font: 700 2.04vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.choose-person-actions {
  display: flex;
  gap: 0.799vw;
  margin-top: 4.629vh;
}

.choose-person-actions-btn-text {
  font: 700 1.49vh 'RedHatText-Regular', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

#ok-btn {
  background-color: #042447;
}

#cancel-btn {
  background-color: #FFFFFF1A;
}

.choose-person-actions-btn {
  width: 8.3334vw;
  height: 4.7223vh;
  border-radius: 5vw;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
