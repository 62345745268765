import { GenericObject, SubFilterMap } from '@/models'
import * as XLSX from 'xlsx'

// Конвертор даты Unit к дд.мм.гггг
export function unitDateConvert (unitDate: number) {
  const date = new Date(unitDate)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  return `${day}.${month}.${year}`
}

// Конвертор даты времени Unit
export function unitTimestampConvert (unitTime: number | undefined, type: string = 'date') {
  if (unitTime === 0 || !unitTime) {
    return ''
  }

  if (unitTime < 1000000000000) {
    unitTime = unitTime * 1000
  }
  const date = new Date(unitTime)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  if (type === 'date') {
    return `${day}.${month}.${year}`
  }

  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')

  if (type === 'datetime') {
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
  }
  if (type === 'time') {
    return `${hours}:${minutes}`
  }
  return `${hours}:${minutes}:${seconds}`
}

// Функция-генератор задержки ms - миллисекунды
export function delay (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function getPreviousMonthInfo (): [number, number] {
  const today = new Date()
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth.getTime() - 1)

  const monthNumber = lastDayOfPreviousMonth.getMonth() + 1
  const daysInMonth = lastDayOfPreviousMonth.getDate()

  return [daysInMonth, monthNumber]
}

export function getStartOfDayInMilliseconds (): number {
  const now = new Date()
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  return startOfDay.getTime()
}

export function getEndOfDayInMilliseconds (): number {
  const now = new Date()
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)
  return endOfDay.getTime()
}

export function getMonthStartEnd (month: number | null = null, year: number | null = null): [number, number] {
  const today = new Date()
  if (!month) {
    month = today.getMonth() + 1
  }
  if (!year) {
    year = today.getFullYear()
  }
  const startDate = new Date(year, month - 1, 1)
  const startMs = startDate.getTime()
  if (month === today.getMonth() + 1 &&
    year === today.getFullYear()) {
    return [startMs, today.getTime()]
  }
  const endDate = new Date(year, month, 1)
  endDate.setMilliseconds(-1)
  const endMs = endDate.getTime()
  return [startMs, endMs]
}

export function getTimeStampOfCurrentTime (withDate: boolean = false) {
  const now = new Date()
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  return withDate ? now.getTime() : now.getTime() - startOfDay.getTime()
}

export function getLastMonthTimestamp (): number {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()
  const day = currentDate.getDate()

  let lastMonthDate: Date

  if (month === 0) {
    // Январь, поэтому предыдущий месяц - декабрь прошлого года
    lastMonthDate = new Date(year - 1, 11, day, 23, 59, 59, 999)
  } else {
    // Пытаемся создать дату для прошлого месяца с тем же днем
    try {
      lastMonthDate = new Date(year, month - 1, day, 23, 59, 59, 999)
    } catch (e) {
      // Если день не существует, получаем последний день прошлого месяца
      const tempDate = new Date(year, month, 0) // 0 день текущего месяца - это последний день предыдущего месяца
      lastMonthDate = new Date(year, month - 1, tempDate.getDate(), 23, 59, 59, 999)
    }
  }

  return lastMonthDate.getTime()
}

export function dateConvert (date: Date, needTime: boolean = false) {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  if (needTime) {
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
  } else return `${day}.${month}.${year}`
}

export function getTimezoneOffsetInHours (): number {
  const currentDateTime = new Date()
  const timezoneOffsetInMinutes = currentDateTime.getTimezoneOffset()
  return -timezoneOffsetInMinutes / 60
}

export function formatRange (dates: Array<Date>) {
  if (!dates || dates.length !== 2) return ''
  if (dates[1] == null) {
    dates[1] = new Date(dates[0].getFullYear(), dates[0].getMonth(), dates[0].getDate(), 23, 59, 59)
  }
  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  return `${formatDate(dates[0])} - ${formatDate(dates[1])}`
}

export function getStartEndOfDay (day: Date = new Date()): [number, number] {
  const startOfDay = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0)
  const endOfDay = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59, 59)

  const startOfDayUnix = Math.floor(startOfDay.getTime() / 1000)
  const endOfDayUnix = Math.floor(endOfDay.getTime() / 1000)

  return [startOfDayUnix, endOfDayUnix]
}

export function getUnixTimestamps () {
  const oneDayInMillis = 24 * 60 * 60 * 1000

  const getStartOfDay = (date: Date) => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    return newDate.getTime()
  }

  const getEndOfDay = (date: Date) => {
    const newDate = new Date(date)
    newDate.setHours(23, 59, 59, 999)
    return newDate.getTime()
  }

  const today = new Date()
  const yesterday = new Date(today.getTime() - oneDayInMillis)

  const currentWeekStart = new Date(today.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)))
  const currentWeekEnd = new Date(currentWeekStart.getTime() + 6 * oneDayInMillis)

  const prevWeekStart = new Date(currentWeekStart.getTime() - 7 * oneDayInMillis)
  const prevWeekEnd = new Date(currentWeekEnd.getTime() - 7 * oneDayInMillis)

  const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
  const currentMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)

  const prevMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
  const prevMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

  const todayArr = getStartEndOfDay()
  return {
    today: [todayArr[0] * 1000, todayArr[1] * 1000],
    yesterday: [getStartOfDay(yesterday), getEndOfDay(yesterday)],
    currentWeek: [getStartOfDay(currentWeekStart), getEndOfDay(currentWeekEnd)],
    prevWeek: [getStartOfDay(prevWeekStart), getEndOfDay(prevWeekEnd)],
    currentMonth: [getStartOfDay(currentMonthStart), getEndOfDay(currentMonthEnd)],
    prevMonth: [getStartOfDay(prevMonthStart), getEndOfDay(prevMonthEnd)]
  }
}

export function convertToSubFilterMap (obj: GenericObject | null): SubFilterMap | null {
  if (typeof obj !== 'object' || obj === null) {
    return null
  }
  const result: SubFilterMap = {}
  if (Object.keys(obj).length > 0) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key]
        if (typeof value === 'string' || typeof value === 'number') {
          result[key] = value.toString()
        } else if (typeof value === 'object') {
          result[key] = value
        } else {
          return null
        }
      }
    }
  }
  return result
}

export interface ExcelData {
  [key: string]: string | number
}

export function exportToExcel (data: ExcelData[], fileName: string) {
  const ws = XLSX.utils.json_to_sheet(data)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Data')
  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export function dataUpdTime () {
  const date = new Date()

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes} ${day}.${month}.${year}`
}

export function getTimeStampUTC (hours: number, minutes: number, timezoneOffset: number) {
  const date = new Date(Date.UTC(1970, 4, 1, hours, minutes))
  const utcTimestamp = date.getTime() - (timezoneOffset * 3600 * 1000)
  return utcTimestamp
}

export async function base64ToBlob (base64Data: string) {
  const matches = base64Data.match(/^data:(.+?);base64,(.*)$/)
  if (!matches || matches.length !== 3) {
    throw new Error('Invalid base64 data format')
  }

  const contentType = matches[1]
  const base64 = matches[2]
  const byteCharacters = atob(base64)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return await blob.arrayBuffer()
}

export function getHoursMinutesFromTimestamp (
  timestamp: number
): Record<string, number> {
  const date = new Date(timestamp)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return { hours, minutes }
}
