<template>
  <div class="person-item-wrapper">
    <div>
      <div class="person-item-header"
        v-click-outside="clickOutside"
      >
        <div class="person-item-image-name">
          <div class="person-item-image">
            <img v-if="state.imageSrc && state.imageSrc != ''"
              :src="`data:image/jpeg;base64, ${state.imageSrc}`"
            >
            <img :src="noPhoto" v-else>
          </div>
          <div class="person-item-info-wrapper">
            <span class="person-item-info-name">{{ props.person.name }}</span>
            <span class="person-item-info-pos">
              {{ unitTimestampConvert(props.person.arrivalTime, 'time') }} - {{ unitTimestampConvert(props.person.leaveTime, 'time') }}
            </span>
          </div>
        </div>
        <div class="click-area" @click="state.popupIsOpen = !state.popupIsOpen">
          <img class="person-item-header-menu-btn button-click" :src="menu"
          >
        </div>
      </div>
      <div class="person-popup" v-if="state.popupIsOpen"
      >
        <div class="person-popup-row button-click"
          @click="action('red')"
        >
          <img :src="editIcon">
          <span class="person-popup-text">Редактировать</span>
        </div>
        <div class="person-popup-row button-click"
          @click="action('del')"
        >
          <img :src="deleteIcon">
          <span class="person-popup-text">Удалить</span>
        </div>
      </div>
    </div>
    <div class="person-item-body">
      <div class="person-item-infobloc">
        <span class="person-item-info-pos">Должность</span>
        <div class="person-item-info-multiple" v-if="state.positionsArray.length > 0">
          <span v-for="(pos, index) in state.positionsArray" :key="index"
            class="person-item-info-data"
          >
            {{ `${pos}${index + 1 == state.positionsArray.length ? '' : ', '}` }}
          </span>
        </div>
        <div class="person-item-info-multiple" v-else>
          <span class="person-item-info-data">
            Не указана
          </span>
        </div>
      </div>
      <div class="person-item-infobloc">
        <span class="person-item-info-pos">Отдел</span>
        <div class="person-item-info-multiple" v-if="state.departmentsArray.length > 0">
          <span v-for="(dep, index) in state.departmentsArray" :key="index"
            class="person-item-info-data"
          >
            {{ `${dep}${index + 1 == state.departmentsArray.length ? '' : ', '}` }}
          </span>
        </div>
        <div class="person-item-info-multiple" v-else>
          <span class="person-item-info-data">
            Не указан
          </span>
        </div>
      </div>
      <div class="person-item-infobloc">
        <span class="person-item-info-pos">Возможные мин. опоздания</span>
        <span class="person-item-info-data">
          {{ person.lateTime }}
        </span>
      </div>
      <div class="person-item-infobloc">
        <span class="person-item-info-pos">Возможные мин. раннего ухода</span>
        <span class="person-item-info-data">
          {{ person.earlyLeave }}
        </span>
      </div>
    </div>
    <img class="person-item-ellipse" :src="ellipse">
  </div>
</template>

<script setup lang="ts">
import { reactive, PropType, onBeforeMount } from 'vue'
import menu from '@/assets/icon/kebab.svg'
import ellipse from '@/assets/images/ellipse_5.svg'
import { Employee, Universal } from '@/models'
import { useImagesYarosStore } from '@/stores/imagesYaros'
import { unitTimestampConvert } from '@/globalFunctions'
import noPhoto from '@/assets/images/no_profile.svg'
import editIcon from '@/assets/icon/org_item/edit.svg'
import deleteIcon from '@/assets/icon/org_item/delete.svg'

const emits = defineEmits(['action'])

const props = defineProps({
  person: {
    type: Object as PropType<Employee>,
    required: true
  },
  positions: {
    type: Array as PropType<Universal[]>,
    required: true
  },
  departments: {
    type: Array as PropType<Universal[]>,
    required: true
  },
  imageStorage: {
    type: String,
    required: true
  }
})

const imagesStore = useImagesYarosStore()

const state = reactive({
  popupIsOpen: false,
  imageSrc: '',
  positionsArray: [] as string[],
  departmentsArray: [] as string[],
  positions: '',
  departments: ''
})

onBeforeMount(async () => {
  const departmentsTmp: Set<string> = new Set()
  const positionsTmp: Set<string> = new Set()

  if (props.person.depId !== '') {
    departmentsTmp.add(props.person.depId)
  }

  props.person.depArr.forEach(dep => departmentsTmp.add(dep))

  if (props.person.posOrg !== '') {
    positionsTmp.add(props.person.posOrg)
  }

  props.person.posArr.forEach(pos => positionsTmp.add(pos))

  state.positionsArray = props.person.posArr
    .map(pos => props.positions.find(position => position.id === pos)?.name)
    .filter((name): name is string => !!name)

  state.departmentsArray = props.person.depArr
    .map(dep => props.departments.find(department => department.id === dep)?.name)
    .filter((name): name is string => !!name)

  await loadImage()
})

function clickOutside () {
  if (state.popupIsOpen) {
    state.popupIsOpen = false
  }
}

async function loadImage () {
  try {
    const cachedPhoto = localStorage.getItem(props.person.photo)
    if (cachedPhoto) {
      state.imageSrc = cachedPhoto
    } else {
      const result = await imagesStore.getImage(props.imageStorage, props.person.photo, 55)
      if (result) {
        localStorage.setItem(props.person.photo, result)
        state.imageSrc = result
      }
    }
  } catch (error) {
    state.imageSrc = await imagesStore.getImage(props.imageStorage, props.person.photo, 55)
  }
}

function action (type: string) {
  state.popupIsOpen = false
  emits('action', { type, id: props.person.id })
}
</script>

<style scoped>
.person-item-wrapper,
.person-item-header {
  display: flex;
  justify-content: space-between;
}

.person-item-wrapper {
  flex-direction: column;
  width: 15.1042vw;
  background-color: #2D4B6B;
  border-radius: 2.5vh;
  padding: 2.315vh;
  position: relative;
  height: 25vh;
  overflow-y: hidden;
}

.person-item-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.925vh;
  z-index: 2;
}

.person-item-info-name {
  font: 700 0.8vw 'RedHatText-Bold', 'OpenSans', sans-serif;
  color: var(--primary-font-color);
}

.person-item-info-pos {
  font: 400 0.93vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color-a70);
}

.person-item-info-data {
  font: 400 1.112vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.person-item-icon-wrapper {
  background-color: var(--primary-font-color-a5);
  padding: 0.926vh 0.426vw;
  border-radius: 0.926vh;
  height: 1.3vh;
  width: 1.3vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.person-item-ellipse {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.person-item-image {
  width: 6.019vh;
  height: 6.019vh;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.person-item-header-menu-btn {
  height: 1.5745vh;
}

.person-item-body {
  display: flex;
  flex-direction: column;
  gap: 1.39vh;
  margin-top: 1.852vh;
}

.person-item-infobloc {
  display: flex;
  flex-direction: column;
  gap: 0.47vh;
}

.person-item-image-name {
  display: flex;
  gap: 0.782vw;
  align-items: center;
}

.person-item-info-multiple {
  display: flex;
  flex-wrap: wrap;
  gap: 0.47vh;
}

.person-popup {
  position: absolute;
  left: auto;
  display: flex;
  flex-direction: column;
  background-color: #2D4B6B;
  border-radius: 2.5vh;
  padding: 1.389vh 0.534vw;
  width: fit-content;
  z-index: 3;
  right: 2.315vh;
  top: 5.2vh;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.15);
}

.person-popup-row {
  display: flex;
  gap: 0.64vw;
  height: 4.63vh;
  width: 9.59vw;
  align-items: center;
  padding-left: 1.851vh;
}

.person-popup-text {
  font: 400 1.386vh 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  color: var(--primary-font-color);
}

.click-area {
  display: inline-block;
  padding: 0.93vh;
}
</style>
