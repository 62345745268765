import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosMain } from '@/axios-setup'
import { Employee, EmploysResp, Positions, PositionsMap } from '../models'

export const useEmploysStore = defineStore('employs', {
  state: () => ({
    employsResp: {} as EmploysResp,
    employs: [] as Employee[],
    positions: [] as Positions
  }),
  actions: {
    async getEmploysAction (orgId: string) {
      try {
        const response = await axiosMain.get<EmploysResp>(`/Per/${orgId}`)
        this.employsResp = response.data
        this.employs = this.employsResp.persons
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async getPositionsAction (orgId: string) {
      try {
        const response = await axiosMain.get<PositionsMap>(`/Pos/${orgId}`)
        this.positions = response.data.positions
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async registrationPerson (orgId: string, person: Employee) {
      try {
        const response = await axiosMain.post<EmploysResp>(`/Per/${orgId}`, person)
        return response
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async patchPerson (orgId: string, personsData: Array<object>) {
      try {
        const response = await axiosMain.patch<EmploysResp>(`/Per/${orgId}`,
          { persons: personsData }
        )
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async deletePerson (orgId: string, id: string) {
      try {
        const response = await axiosMain.delete<Record<string, string>>(`/Per/${orgId}`,
          { params: { id } }
        )
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getEmploys: (state) => state.employs,
    getPositions: (state) => state.positions
  }
})
