<template>
    <div class="fix-rep-header">
        <div class="fix-rep-back-block">
            <img class="button-click filter-icon" :src="back" @click="emits('to-dashboard')">
            <span class="fix-rep-header-label">{{ props.reportName }}</span>
        </div>
        <div class="fix-rep-buttons-block">
            <img class="button-click filter-icon" :src="filter" @click="eventBus.emit('showFilter')">
            <img class="button-click filter-icon" :src="reload" @click="eventBus.emit('reloadReport')">
            <img class="button-click filter-icon" :src="excel" @click="eventBus.emit('excelExport')">
        </div>
        <div class="filters-wrapper">
            <div
                v-for="(filterKey, index) in Object.keys(state.filterObj)"
                :key="index"
            >
                <u-filter-obj
                    v-if="state.filterObj[filterKey].length > 0 && state.filterObj[filterKey] !== 'empty'"
                    :label="`${returnValue(filterKey, state.filterObj[filterKey])}`"
                    :filterKey="filterKey"
                    @del-filter="delFilter"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import back from '@/assets/icon/back.svg'
import reload from '@/assets/icon/reload.svg'
import excel from '@/assets/icon/excel-export.svg'
import { eventBus } from '@/eventBus'
import filter from '@/assets/icon/filter.svg'
import { reactive, inject } from 'vue'
import { Employee, Departments, Positions, SubFilterMap } from '@/models'
import { ArrayMap } from '@/components/UI/UDropdownFilter.vue'
import { unitTimestampConvert, convertToSubFilterMap } from '@/globalFunctions'

const days: ArrayMap[] = inject('days', [])

const props = defineProps({
  reportName: {
    type: String,
    required: true
  },
  persons: {
    type: Array as () => Employee[],
    required: true
  },
  departments: {
    type: Array as () => Departments,
    required: true
  },
  positions: {
    type: Array as () => Positions,
    required: true
  }
})

const emits = defineEmits(['to-dashboard'])

const state = reactive({
  filterObj: {} as SubFilterMap
})

eventBus.on('filter', (obj) => {
  const validObj = convertToSubFilterMap(obj)
  if (validObj !== null) {
    state.filterObj = validObj
  }
})

function returnValue (key: string, value: string | Array<number>) {
  if (key === 'person') {
    const person = props.persons?.find(item => item.id === value)
    return person ? person.name : 'Неизвестно'
  }
  if (key === 'department') {
    const department = props.departments?.find(item => item.id === value)
    return department ? department.name : 'Неизвестно'
  }
  if (key === 'position') {
    const position = props.positions?.find(item => item.id === value)
    return position ? position.name : 'Неизвестно'
  }
  if (key === 'date') {
    if (typeof value === 'string') {
      const date = days.find(item => item.value === value)
      return date ? date.text : 'Неизвестно'
    } else {
      return `${unitTimestampConvert(value[0])} - ${unitTimestampConvert(value[1])}`
    }
  }
}

function delFilter (filterKey: string) {
  state.filterObj[filterKey] = ''
  eventBus.emit('filter', state.filterObj)
}
</script>

<style>
.fix-rep-header {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.fix-rep-back-block {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.fix-rep-header-label {
    font: 1.35vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.fix-rep-buttons-block {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.filters-wrapper {
    display: flex;
    gap: 1.30vw;
}

.filter-icon {
  height: 1vw;
}
</style>
