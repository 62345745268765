<template>
    <transition name="udialog-fade">
        <div class="filter-overlay" v-if="true"
          @click.self="closeAllDropDowns()"
        >
            <div class="filter-pad"
              @click.self="closeAllDropDowns()"
            >
                <div class="filter-header">
                    <span class="filter-header-text">Фильтры</span>
                    <img :src="cancel"
                        class="filter-cancel button-click"
                        @click="emits('close')"
                    >
                </div>
                <div class="filter-items-wrapper">
                    <div class="filter-item-wrapper"
                      v-if="!props.filters || props.filters.date">
                        <span class="filter-label">Период</span>
                        <u-dropdown-filter v-if="state.datePreset"
                            :array="days"
                            :isOpen="state.periodIsOpen"
                            :selected="state.periodEl"
                            @click-event="toggleDropDown('periodIsOpen')"
                            @choose-row="changePeriod"
                        />
                        <VueDatePicker v-else
                            range validate
                            v-model="date"
                            locale="ru"
                            cancel-text="Отмена"
                            select-text="Ок"
                            :format="formatRange"
                            class="vue-datepicker"
                            placeholder="Выберите даты"
                            >
                        </VueDatePicker>
                        <span class="filter-open-picker button-click"
                            @click="changeDateTypeFilter()"
                        >
                            {{ state.dateSwitcherInfo }}
                        </span>
                    </div>
                    <div class="filter-item-wrapper"
                      v-if="!props.filters || props.filters.department">
                        <span class="filter-label">По отделам</span>
                        <u-dropdown-filter :array="state.departmentsDropArr"
                            :isOpen="state.departmentsIsOpen"
                            :selected="state.departmentEl"
                            @click-event="toggleDropDown('departmentsIsOpen')"
                            @choose-row="changeDepartment"
                        />
                    </div>
                    <div class="filter-item-wrapper"
                      v-if="!props.filters || props.filters.department">
                        <span class="filter-label">По должностям</span>
                        <u-dropdown-filter :array="state.positionsDropArr"
                            :isOpen="state.positionsIsOpen"
                            :selected="state.positionEl"
                            @click-event="toggleDropDown('positionsIsOpen')"
                            @choose-row="changePosition"
                        />
                    </div>
                    <div class="filter-item-wrapper"
                      v-if="!props.filters || props.filters.employs">
                        <span class="filter-label">По сотрудникам</span>
                        <u-dropdown-filter :array="state.employesDropArr"
                            :isOpen="state.employesIsOpen"
                            :selected="state.employeeEl"
                            @click-event="toggleDropDown('employesIsOpen')"
                            @choose-row="changeEmployee"
                        />
                    </div>
                </div>
                <div class="filter-act-btns">
                    <button class="settings-action-btn button-click"
                        id="save-btn"
                        @click=returnResults>
                        Применить
                    </button>
                    <button class="settings-action-btn button-click"
                        id="cancel-btn"
                        @click="emits('close')">
                        Отменить
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import cancel from '@/assets/icon/cancel-dialog.svg'
import { ArrayMap } from '@/components/UI/UDropdownFilter.vue'
import { reactive, ref, onBeforeMount, inject } from 'vue'
import { formatRange } from '@/globalFunctions'
import { eventBus } from '@/eventBus'
import { Departments, Positions, Employee, SubFilterMap } from '@/models'

const emits = defineEmits(['click-res', 'close', 'filter-set'])

const date = ref([new Date(), new Date()])

const days: ArrayMap[] = inject('days', [])

export interface FilterParams {
  date: string | Array<number>;
  person: string;
  department: string;
}

export interface DatesMap {
  [key: string]: Array<number>
}

const props = defineProps({
  filters: {
    type: Object as () => Record<string, boolean>,
    default: null,
    required: false
  },
  settedFilters: {
    type: Object as () => SubFilterMap,
    required: false
  }
})

const state = reactive({
  periodIsOpen: false,
  periodEl: 0,
  departmentsIsOpen: false,
  positionsIsOpen: false,
  departmentEl: -1,
  positionEl: -1,
  departmentId: '',
  positionId: '',
  employesIsOpen: false,
  employeeEl: -1,
  employeeId: '',
  datePreset: true,
  dateSwitcherInfo: 'Задать промежуток',
  departments: [] as Departments,
  positions: [] as Positions,
  departmentsDropArr: [] as ArrayMap[],
  positionsDropArr: [] as ArrayMap[],
  employes: [] as Employee[],
  employesDropArr: [] as ArrayMap[]
})

onBeforeMount(() => {
  const departmentsData = sessionStorage.getItem('departments')
  if (departmentsData) {
    state.departments = JSON.parse(departmentsData)
    state.departmentsDropArr = state.departments.map(item => ({
      text: item.name,
      value: item.id
    }))
  }
  const positionsData = sessionStorage.getItem('positions')
  if (positionsData) {
    state.positions = JSON.parse(positionsData)
    state.positionsDropArr = state.positions.map(item => ({
      text: item.name,
      value: item.id
    }))
  }
  const persons = sessionStorage.getItem('persons')
  if (persons) {
    state.employes = JSON.parse(persons)
    state.employesDropArr = state.employes.map(item => ({
      text: item.name,
      value: item.id
    }))
  }
  if (props.settedFilters) {
    // eslint-disable-next-line dot-notation
    if (props.settedFilters['date'] && props.settedFilters['date'] !== '') {
      // eslint-disable-next-line dot-notation
      state.periodEl = days.findIndex(day => day.value === props.settedFilters!['date']) + 1
    }
    // eslint-disable-next-line dot-notation
    if (props.settedFilters['person']) {
      // eslint-disable-next-line dot-notation
      state.employeeId = props.settedFilters['person']
      state.employeeEl = state.employesDropArr.findIndex(per => per.value === state.employeeId) + 1
    }
    // eslint-disable-next-line dot-notation
    if (props.settedFilters['department']) {
      // eslint-disable-next-line dot-notation
      state.departmentId = props.settedFilters['department']
      state.departmentEl = state.departmentsDropArr.findIndex(dep => dep.value === state.departmentId) + 1
    }
    // eslint-disable-next-line dot-notation
    if (props.settedFilters['position']) {
      // eslint-disable-next-line dot-notation
      state.positionId = props.settedFilters['position']
      state.positionEl = state.positionsDropArr.findIndex(pos => pos.value === state.positionId) + 1
    }
  }
})

function changeDateTypeFilter () {
  state.datePreset = !state.datePreset
  if (state.datePreset) {
    state.dateSwitcherInfo = 'Задать промежуток'
  } else {
    state.dateSwitcherInfo = 'Выбрать вручную'
  }
}

function changePeriod (period: number) {
  state.periodEl = period
}

function changeDepartment (index: number) {
  state.departmentEl = index
  if (state.departmentEl > -1) {
    state.departmentId = state.departmentsDropArr[index - 1].value.toString()
  } else {
    state.departmentId = ''
  }
}

function changePosition (index: number) {
  state.positionEl = index
  if (state.positionEl > -1) {
    state.positionId = state.positionsDropArr[index - 1].value.toString()
  } else {
    state.positionId = ''
  }
}

function changeEmployee (index: number) {
  state.employeeEl = index
  if (state.employeeEl > -1) {
    state.employeeId = state.employesDropArr[index - 1].value.toString()
  } else {
    state.employeeId = ''
  }
}

function returnResults () {
  let period
  if (state.datePreset) {
    period = state.periodEl - 1 < 0 ? '' : days[state.periodEl - 1].value
  } else {
    const seconDate = date.value[1] == null ? new Date(date.value[0].getFullYear(), date.value[0].getMonth(), date.value[0].getDate(), 23, 59, 59) : date.value[1]
    period = [date.value[0].getTime(), seconDate.getTime()]
  }
  const filterParams = {
    date: period,
    person: state.employeeId,
    department: state.departmentId,
    position: state.positionId
  }
  eventBus.emit('filter', filterParams)
  emits('filter-set', filterParams)
  emits('close')
}

type StateKeys = 'periodIsOpen' | 'employesIsOpen' | 'departmentsIsOpen' | 'positionsIsOpen'

function closeAllDropDowns () {
  state.periodIsOpen = false
  state.employesIsOpen = false
  state.departmentsIsOpen = false
}

function toggleDropDown (property: StateKeys) {
  const prevState = state[property]
  closeAllDropDowns()
  state[property] = !prevState
}

</script>

<style>
.filter-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-pad {
  width: 22rem;
  background-image: linear-gradient(245.9deg, #2D4B6B -40.66%, #042447 131.18%);
  padding: 2rem;
  border-radius: 2rem;
}

.filter-cancel {
  align-self: end;
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.filter-header-text {
  color: var(--primary-font-color);
  font: 1.05rem 'RedHatText-Bold', 'OpenSans', sans-serif;
  font-weight: 700;
}

.filter-label {
  color: var(--primary-font-color);
  font: 0.7rem 'RedHatText-SemiBold', 'OpenSans', sans-serif;
  font-weight: 500;
}

.filter-open-picker {
  color: var(--primary-font-color-blue);
  font: 0.565rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
}

.filter-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.filter-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filter-act-btns {
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
}

.settings-action-btn {
  font: 0.76rem 'RedHatText-Bold', 'OpenSans', sans-serif;
  font-weight: 700;
  width: 8rem;
  padding: 1rem 1.7rem 1rem 1.7rem;
  border-radius: 2rem;
  border: none;
  align-self: end;
}

#save-btn {
  background-color: #042447;
  color: var(--primary-font-color);
}

#cancel-btn {
  background-color: #FFFFFF1A;
  color: var(--primary-font-color-a70);
}
</style>
