<template>
    <div class="fines-rep-main-wrapper">
        <div v-if="state.isLoaded" class="fines-rep-wrapper">
            <div class="fines-rep-header" @click="handleHeaderClick">
                <div class="fines-rep-row-date">
                    <span>
                        Дата
                    </span>
                </div>
                <div class="fines-rep-row-name">
                    <span>
                        Сотрудник
                    </span>
                </div>
                <div class="fines-rep-row-dep-pos">
                    <div class="fines-rep-legend-block">
                        <span>
                            {{ state.subPosDepFirstRow }}
                        </span>
                        <span>
                            {{ state.subPosDepSecondRow }}
                        </span>
                    </div>
                </div>
                <div class="fines-rep-row-dep-amount">
                    <span>
                        Сумма
                    </span>
                </div>
                <div class="fines-rep-row-dep-type">
                    <div class="fines-rep-legend-block button-click" @click="reasonFilter">
                        <span>
                            Причина
                        </span>
                        <span>
                            {{ state.reason === '' ? 'Все' : feeTypeMap[state.reason] }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="fines-rep-body">
                <div v-for="(item, index) in state.reportData" :key="index">
                    <div v-show="item.date && checkConditions(item)">
                        <div class="fines-rep-row">
                            <div class="fines-rep-row-date">
                                <span>{{ unitTimestampConvert(item.date, 'datetime') }}</span>
                            </div>
                            <div class="fines-rep-row-name">
                                <span>{{ findName(item.perId, 'persons') }}</span>
                            </div>
                            <div class="fines-rep-row-dep-pos-data">
                                <span>{{ findName(item.depId, 'departments') }}</span>
                                <span>{{ findName(item.posId, 'positions') }}</span>
                            </div>
                            <div class="fines-rep-row-dep-amount">
                                <span>{{ item.amount }}</span>
                            </div>
                            <div class="fines-rep-row-dep-type">
                                <span>
                                    {{ feeTypeMap[item.feeType] }}
                                </span>
                            </div>
                        </div>
                        <div class="row-delimeter"></div>
                    </div>
                </div>
            </div>
            <div class="fines-rep-load-prev-month button-click" @click="loadMoreData">
                <span v-if="!state.loading">
                    {{ `Загрузить данные за ${state.prevMonthStr}` }}
                </span>
                <u-spinner v-else />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeMount, reactive, watch, ref } from 'vue'
import {
  FeeStatisticList,
  FeeStatisticMap,
  Departments,
  Positions,
  Employee,
  SubFilterMap
} from '@/models'
import {
  unitTimestampConvert,
  getMonthStartEnd,
  getPreviousMonthInfo
} from '@/globalFunctions'
import { useFinesStore } from '@/stores/fines'
import { useCookesStore } from '@/stores/cookies'
import cloneDeep from 'lodash/cloneDeep'

const finesStore = useFinesStore()
const cookies = useCookesStore()

const subFilterMap: SubFilterMap = {
  pos: 'Должность',
  dep: 'Подразделение',
  default: 'Сортировка'
}

const dates = reactive({
  dateBegin: 0,
  dateEnd: 0,
  startDates: [0, 0]
})

const state = reactive({
  orgId: '',
  isLoaded: false,
  reportData: [] as FeeStatisticList,
  depPos: 'default',
  subPosDepFirstRow: subFilterMap.dep,
  subPosDepSecondRow: subFilterMap.pos,
  id: '',
  reason: '',
  prevMonth: 0,
  prevYear: 0,
  prevMonthStr: '',
  loading: false,
  filteredData: [] as FeeStatisticList
})

const date = ref([new Date(), new Date()])

const dataSetsMap = reactive({
  persons: [] as Employee[],
  departments: [] as Departments,
  positions: [] as Positions
})

const feeTypeMap: { [key: string]: string } = {
  late: 'Опоздание',
  leave: 'Ранний уход'
}

// const columns = [
//   { label: 'Дата', field: 'date', dataFormat: (val: number) => unitTimestampConvert(val, 'datetime') },
//   { label: 'Сотрудник', field: 'perId', dataFormat: (id: string) => findName(id, 'persons') },
//   { label: 'Подразделение', field: 'depId', dataFormat: (id: string) => findName(id, 'departments') },
//   { label: 'Должность', field: 'posId', dataFormat: (id: string) => findName(id, 'positions') },
//   { label: 'Сумма', field: 'amount' },
//   { label: 'Причина', field: 'feeType', dataFormat: (type: string) => feeTypeMap[type] }
// ]

onBeforeMount(async () => {
  await cookies.getCookie()
  state.orgId = cookies.getCookies.orgId
  const currYear = new Date().getFullYear()
  const prevMonth = getPreviousMonthInfo()[1]
  state.prevMonth = prevMonth - 1 === 0 ? 12 : prevMonth - 1
  state.prevYear = state.prevMonth === 12 ? currYear - 1 : currYear
  const prevMonthTimestamps = getMonthStartEnd(prevMonth, state.prevYear)
  const timestamps = getMonthStartEnd()
  dates.dateBegin = prevMonthTimestamps[0]
  dates.dateEnd = timestamps[1]
  dates.startDates = [dates.dateBegin, dates.dateEnd]
  date.value = [new Date(dates.dateBegin), new Date(dates.dateEnd)]
  const personsData = sessionStorage.getItem('persons')
  if (personsData) {
    dataSetsMap.persons = JSON.parse(personsData)
  }
  const departmentsData = sessionStorage.getItem('departments')
  if (departmentsData) {
    dataSetsMap.departments = JSON.parse(departmentsData)
  }
  const positionsData = sessionStorage.getItem('positions')
  if (positionsData) {
    dataSetsMap.positions = JSON.parse(positionsData)
  }
  state.reportData = JSON.parse(sessionStorage.getItem('fee-statistic') || '[]')
  state.filteredData = cloneDeep(state.reportData)
  state.isLoaded = true
})

function findName (id: string, type: string) {
  const item = dataSetsMap[type as keyof typeof dataSetsMap].find(item => item.id === id)
  return item ? item.name : 'Неизвестно'
}

watch(() => state.depPos, (newValue) => {
  state.id = ''
  if (newValue !== 'default') {
    state.subPosDepFirstRow = subFilterMap[newValue]
    state.subPosDepSecondRow = 'Все'
  } else {
    state.subPosDepFirstRow = subFilterMap.dep
    state.subPosDepSecondRow = subFilterMap.pos
  }
})

watch(() => date.value, (newValue) => {
  if (!newValue) {
    date.value = [new Date(dates.startDates[0]), new Date(dates.startDates[1])]
    dates.dateBegin = date.value[0].getTime()
    dates.dateEnd = date.value[1].getTime()
    return
  }
  if (!newValue[0]) {
    date.value[0] = new Date()
  }
  if (!newValue[1]) {
    if (date.value[0] > new Date()) {
      date.value[1] = date.value[0]
    } else {
      date.value[1] = new Date()
    }
  }
  if (!(dates.dateBegin === newValue[0].getTime() &&
    dates.dateEnd === newValue[1].getTime())) {
    dates.dateBegin = newValue[0].getTime()
    dates.dateEnd = newValue[1].getTime()
  }
})

function reasonFilter () {
  if (state.reason === '') {
    state.reason = Object.keys(feeTypeMap)[0]
  } else {
    const keysArr = Object.keys(feeTypeMap)
    const currIndex = keysArr.findIndex(item => item === state.reason)
    if (currIndex === keysArr.length - 1) {
      state.reason = ''
    } else {
      state.reason = Object.keys(feeTypeMap)[currIndex + 1]
    }
  }
}

function checkConditions (item: FeeStatisticMap) {
  // const datesRange = item.date >= dates.dateBegin && item.date <= dates.dateEnd
  const reasonConditon = state.reason === '' ? true : item.feeType === state.reason
  return reasonConditon
}

async function loadMoreData () {
  state.loading = true
  const timestamps = getMonthStartEnd(state.prevMonth, state.prevYear)
  await finesStore.getFeeAction(state.orgId, timestamps[0], timestamps[1])
  date.value[0] = new Date(timestamps[0])
  state.reportData = [...finesStore.getFeeStatisticList, ...state.reportData]
  state.prevMonth = state.prevMonth - 1 === 0 ? 12 : state.prevMonth - 1
  state.loading = false
}

watch(() => state.prevMonth, (newValue) => {
  if (state.isLoaded && newValue === 12) {
    state.prevYear = state.prevYear - 1
  }
  state.prevMonthStr = `${state.prevMonth.toString().padStart(2, '0')}.${state.prevYear}`
})

function handleHeaderClick () {
  filterDataAct()
}
function filterDataAct () {
  state.filteredData = state.reportData.filter(item => checkConditions(item))
}
</script>

<style>
.fines-rep-row, .fines-rep-header {
    display: flex;
}

.fines-rep-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1rem;
}

.fines-rep-body, .fines-rep-header, .fines-rep-load-prev-month {
    background-color: #2D4B6B;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
    border-radius: 1.2rem;
    align-items: center;
    min-height: 3.1rem;
    font: 1rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.fines-rep-header {
    font-size: 0.8rem;
}

.fines-rep-row {
    font-size: 0.6rem;
    height: 3rem;
    align-items: center;
}

.fines-rep-row-date {
    width: 4rem;
}

.fines-rep-row-name {
    width: 8rem;
}

.fines-rep-row-dep-pos {
    width: 8rem;
    font-size: 0.6rem;
    padding: 0 0.8rem;
}

.fines-rep-row-dep-amount {
    width: 4rem;
}

.fines-rep-row-dep-type {
    width: 6rem;
    font-size: 0.6rem;
    padding: 0 0.8rem;
}

.fines-rep-filter {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.8rem;
}

.fines-rep-filter-btn {
  background-color: #2D4B6B;
  color: var(--primary-font-color);
  font-family: 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
  font-size: 0.6rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 8rem;
  align-items: center;
}

.fines-rep-legend-block {
    background-color: #FFFFFF0D;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    padding: 0.4rem 0.8rem;
    gap: 0.4rem;
    width: fit-content;
    height: 2rem;
    justify-content: center;
}

.fines-rep-row-dep-pos-data {
    display: flex;
    flex-direction: column;
    width: 8rem;
    padding: 0.4rem 0.8rem;
    gap: 0.4rem;
}

.fines-rep-load-prev-month {
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    height: 3rem;
}

.fines-rep-body {
    max-height: 25rem;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
