<template>
    <div class="rep-onworkplace-wrapper">
        <div class="rep-onworkplace-header">
            <div class="rep-onworkplace-column-name rep-onworkplace-column-item">
                <span>Имя</span>
            </div>
            <div class="rep-onworkplace-column-is-on-place rep-onworkplace-column-item rep-onworkplace-header-block button-click"
                @click="filterWhoOnPlace(filter.onPlace.type)"
                >
                <span>На месте</span>
                <span :class="[{'rep-onworkplace-row-on-place': filter.onPlace.type === 'no'}]">{{ filter.onPlace.text }}</span>
            </div>
            <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                <span>Время прихода</span>
            </div>
            <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                <span>Время ухода</span>
            </div>
        </div>
        <div class="rep-onworkplace-table">
            <div v-for="person in state.persons" :key="person.id" v-show="showRow(person)">
                <div :class="['rep-onworkplace-table-row button-click',
                    {
                        'rep-onworkplace-row-active': person.id === state.id,
                        'rep-onworkplace-row-on-place': !isOnPlace(person.id)
                    }]"
                >
                    <div class="rep-onworkplace-column-name rep-onworkplace-column-item">
                        <span>{{ person.name.trim() }}</span>
                    </div>
                    <div class="rep-onworkplace-column-is-on-place rep-onworkplace-column-item">
                        <span>{{ isOnPlace(person.id) ? 'Да' : 'Нет' }}</span>
                    </div>
                    <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                        {{ unitTimestampConvert(state.todayFixData.find(item => item.perId === person.id && item.type === 'arrive')?.time ?? 0, 'datetime') !== ''
                            ? unitTimestampConvert(state.todayFixData.find(item => item.perId === person.id && item.type === 'arrive')?.time ?? 0, 'datetime')
                            : '-' }}
                    </div>
                    <div class="rep-onworkplace-column-fine rep-onworkplace-column-item">
                        {{ unitTimestampConvert(state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')?.time ?? 0, 'datetime') !== ''
                            ? unitTimestampConvert(state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')?.time ?? 0, 'datetime')
                            : '-' }}
                    </div>
                </div>
                <div class="row-delimeter"></div>
            </div>
        </div>
    </div>
    <u-filters
        v-if="state.showFilter"
        @close="state.showFilter = false"
        @filter-set="filterSet"
        :filters="{ date: false, employs: true, department: true }"
    />
    <u-dialog v-if="state.showDialog" :header="''" :showButtons="false" :isLoadind="true" />
</template>

<script setup lang="ts">
import { onBeforeMount, reactive } from 'vue'
import { Employee, Fix, SubFilterMap } from '@/models'
import {
  unitTimestampConvert,
  convertToSubFilterMap,
  exportToExcel,
  dateConvert,
  ExcelData
} from '@/globalFunctions'
import { eventBus } from '@/eventBus'
import { useEmploysStore } from '@/stores/employs'

const state = reactive({
  persons: [] as Employee[],
  id: '',
  todayFixData: [] as Fix[],
  showFilter: false,
  orgId: '',
  filterParams: {} as SubFilterMap,
  filteredData: [] as ExcelData[],
  showDialog: false
})

const employs = useEmploysStore()

const filterSteps = [
  { type: 'all', text: 'Все' },
  { type: 'no', text: 'Нет' },
  { type: 'yes', text: 'Да' }
]

const filter = reactive({
  onPlace: filterSteps[0]
})

function filterSet (obj: SubFilterMap) {
  state.filterParams = obj
}

eventBus.on('showFilter', () => {
  state.showFilter = true
})

eventBus.on('reloadReport', async () => {
  await loadData(true)
})

eventBus.on('filter', (obj) => {
  const validObj = convertToSubFilterMap(obj)
  if (validObj !== null) {
    state.filterParams = validObj
  }
})

eventBus.on('excelExport', () => {
  prepareDataForExport()
  exportToExcel(state.filteredData, `on-workplace-${dateConvert(new Date(), true)}`)
})

onBeforeMount(async () => {
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  if (org.orgId) {
    state.orgId = org.orgId
    await loadData()
  }
})

async function loadData (reload: boolean = false) {
  if (reload) {
    state.showDialog = true
    await employs.getEmploysAction(state.orgId)
    sessionStorage.setItem('persons', JSON.stringify(state.persons))
    state.showDialog = false
  }
  state.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  state.todayFixData = JSON.parse(sessionStorage.getItem('today-fix') || '[]')
}

function isOnPlace (id: string): boolean {
  const arriveFix = state.todayFixData.find(item => item.perId === id && item.type === 'arrive')
  const leaveFix = state.todayFixData.find(item => item.perId === id && item.type === 'leave')
  return !!arriveFix && !leaveFix
}

function filterWhoOnPlace (type: string) {
  const index = filterSteps.findIndex(item => item.type === type)
  filter.onPlace = filterSteps[filterSteps.length - 1 !== index ? index + 1 : 0]
}

function prepareDataForExport () {
  state.filteredData = state.persons.filter(showRow).map(person => {
    const arriveFix = state.todayFixData.find(item => item.perId === person.id && item.type === 'arrive')
    const leaveFix = state.todayFixData.find(item => item.perId === person.id && item.type === 'leave')
    const personName = state.persons.find(item => item.id === person.id)?.name
    return {
      Сотрудник: typeof personName === 'string' ? personName : 'Не указан',
      'На месте': isOnPlace(person.id) ? 'Да' : 'Нет',
      'Время прихода': arriveFix ? unitTimestampConvert(arriveFix.time, 'datetime') : '-',
      'Время ухода': leaveFix ? unitTimestampConvert(leaveFix.time, 'datetime') : '-'
    }
  })
}

function showRow (person: Employee) {
  if (Object.keys(state.filterParams).length > 0) {
    if (state.filterParams.person.length > 0 &&
        state.filterParams.person !== person.id) {
      return false
    }
    if (state.filterParams.department.length > 0 &&
        !person.depArr.includes(state.filterParams.department.toString())) {
      return false
    }
  }
  if (filter.onPlace.type !== 'all') {
    const onPlace = isOnPlace(person.id)
    if (filter.onPlace.type === 'no') {
      return !onPlace
    } else {
      return onPlace
    }
  }
  return true
}
</script>

<style>
.rep-onworkplace-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
}

.rep-onworkplace-header, .rep-onworkplace-table {
    display: flex;
    font-family: 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    background-color: #2D4B6B;
    padding: 0.8rem 0 0.8rem 0;
    border-radius: 1.2rem;
}

.rep-onworkplace-table {
    max-height: 70vh;
    min-height: 3rem;
    overflow-y: auto;
}

.rep-onworkplace-header {
    font-size: 0.8rem;
}

.rep-onworkplace-table {
    display: flex;
    flex-direction: column;
}

.rep-onworkplace-column-name {
    width: 10rem;
}

.rep-onworkplace-column-is-on-place {
    width: 5rem;
}

.rep-onworkplace-header-block {
    display: flex;
    height: 100%;
    flex-direction: column;
    font-size: 0.6rem;
    background-color: #FFFFFF0D;
    padding: 0 0 0 0.6rem;
    border-radius: 0.8rem;
    gap: 0.4rem;
    justify-content: center;
}

.rep-onworkplace-column-fine {
    width: 10rem;
}

.rep-onworkplace-column-item {
    padding: 0.3rem 1.2rem 0.3rem 1.2rem;
    align-self: center;
}

.rep-onworkplace-table-row {
    display: flex;
    height: 2.8rem;
    font-size: 0.76rem;
}

.rep-onworkplace-row-active {
    background-color: #FFFFFF0D;
    border-radius: 1rem;
    box-sizing: border-box;
}

.rep-onworkplace-row-on-place {
    color: var(--primary-font-color-error);
}
</style>
