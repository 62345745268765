import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { registerGlobalComponents } from './components/index'
import { clickOutsideDirective } from './clickOutsideDirective'
import './session-storage-types'
import '@/assets/styles/main.css'
import '@/assets/styles/widgets.css'
import '@/assets/styles/animations.css'
import '@/assets/styles/geometry.css'
import '@/assets/styles/scroll.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@/assets/styles/datepicker.css'
import '@vuepic/vue-datepicker/dist/main.css'
import VueExcelXlsx from 'vue-excel-xlsx'

const app = createApp(App)
const pinia = createPinia()

registerGlobalComponents(app)

app.config.globalProperties.$sessionStorage = {
  setItem (key: string, value: unknown) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  getItem (key: string) {
    const value = sessionStorage.getItem(key)
    return value ? JSON.parse(value) : null
  },
  removeItem (key: string) {
    sessionStorage.removeItem(key)
  },
  clear () {
    sessionStorage.clear()
  }
}

app.component('VueDatePicker', VueDatePicker)
app.use(VueExcelXlsx)
app.use(pinia)
app.use(router)
app.directive('click-outside', clickOutsideDirective)
app.mount('#app')
