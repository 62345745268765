<template>
  <div class="settings-wrapper">
    <div class="settings-header">
        <div class="settings-tabs-controller">
            <span class="button-click" @click="state.tab = 'main'"
              :id="state.tab === 'main' ? 'settings-tabs-controller-active' : ''">Основные</span>
            <span v-if="state.showContolTab"
              class="button-click" @click="state.tab = 'control'"
              :id="state.tab === 'control' ? 'settings-tabs-controller-active' : ''">Контроль</span>
        </div>
    </div>
    <div class="settings-main settings-main-main" v-show="state.tab === 'main'">
      <div class="settings-main-row-block">
        <div class="settings-main-row-label-block">
          <span class="settings-main-row-header">Организация</span>
          <span class="settings-main-row-info">Название организации</span>
        </div>
        <div class="input-icon-container">
          <input class="settings-main-input"
            type="text"
            :class="mainSettingsStates.mainInput === 'name' ? 'input-active' : 'input-unactive'"
            :readonly="mainSettingsStates.mainInput !== 'name'"
            v-model="mainSettingsStates.name"
          >
          <div class="img-block input-icon" v-if="mainSettingsStates.mainInput === 'name'" >
            <img :src="ok" @click="saveTmpResult('name')"/>
            <img :src="cancel" @click="closeInput"/>
          </div>
          <img v-else class="input-icon" :src="edit" @click="mainSettingsStates.mainInput = 'name'">
        </div>
      </div>
      <div class="settings-main-row-block">
        <div class="settings-main-row-label-block">
          <span class="settings-main-row-header">ID Telegram</span>
          <span class="settings-main-row-info">Идентификатор Telegram для рассылок</span>
        </div>
        <div class="input-icon-container">
          <input class="settings-main-input"
            type="text"
            :class="mainSettingsStates.mainInput === 'telegram' ? 'input-active' : 'input-unactive'"
            :readonly="mainSettingsStates.mainInput !== 'telegram'"
            @input="handleInput"
            v-model="mainSettingsStates.telegram"
          >
          <div class="img-block input-icon" v-if="mainSettingsStates.mainInput === 'telegram'" >
            <img :src="ok" @click="saveTmpResult('telegram')"/>
            <img :src="cancel" @click="closeInput"/>
          </div>
          <img v-else class="input-icon" :src="edit" @click="mainSettingsStates.mainInput = 'telegram'">
        </div>
      </div>
      <div class="settings-main-row-block">
          <div class="settings-main-row-label-block">
            <span class="settings-main-row-header">E-mail</span>
            <span class="settings-main-row-info">Ваша почта для рассылок и прочего</span>
          </div>
          <div class="input-icon-container">
            <input class="settings-main-input" type="text"
              :class="mainSettingsStates.mainInput === 'email' ? 'input-active' : 'input-unactive'"
              :readonly="mainSettingsStates.mainInput !== 'email'" v-model="mainSettingsStates.email">
            <div class="img-block input-icon" v-if="mainSettingsStates.mainInput === 'email'">
              <img :src="ok" @click="saveTmpResult('email')"/>
              <img :src="cancel" @click="mainSettingsStates.mainInput = ''"/>
            </div>
            <img v-else class="input-icon" :src="edit" @click="mainSettingsStates.mainInput = 'email'">
          </div>
        </div>
        <div class="settings-main-row-block">
          <div class="settings-main-row-label-block">
            <span class="settings-main-row-header">Пароль</span>
            <span class="settings-main-row-info">Пароль для доступа к настройкам</span>
          </div>
          <div class="input-icon-container">
            <input class="settings-main-input" :type="mainSettingsStates.mainInput !== 'lockPassword' ? 'password' : 'text'"
              :class="mainSettingsStates.mainInput === 'lockPassword' ? 'input-active' : 'input-unactive'"
              :readonly="mainSettingsStates.mainInput !== 'lockPassword'" v-model="mainSettingsStates.password">
            <div class="img-block input-icon" v-if="mainSettingsStates.mainInput === 'lockPassword'">
              <img :src="ok" @click="saveTmpResult('password')"/>
              <img :src="cancel" @click="mainSettingsStates.mainInput = ''"/>
            </div>
            <img v-else class="input-icon" :src="edit" @click="mainSettingsStates.mainInput = 'lockPassword'">
          </div>
        </div>
      <div class="settings-main-row-block">
        <div class="settings-main-row-label-block-timezone">
          <span class="settings-main-row-header">Часовой пояс</span>
          <span class="settings-main-row-info">Время, используемое в приложении</span>
        </div>
        <u-dropdown-hours :timezone="mainSettingsStates.timezone"
          @change-timezone="updateTimezone"/>
      </div>
    </div>
    <div class="setting-control-wrapper" v-show="state.tab === 'control'">
        <div class="settings-main settings-main-table">
            <div class="advanced-fines-settings-tabs-controller settings-tabs-controller">
                <span class="button-click" @click="state.subTab = 'late-fine'"
                    :id="state.subTab === 'late-fine' ? 'settings-tabs-controller-active' : ''">Штрафы за опоздание</span>
                <span class="button-click" @click="state.subTab = 'early-leave-fine'"
                    :id="state.subTab === 'early-leave-fine' ? 'settings-tabs-controller-active' : ''">Штрафы за ранний уход</span>
            </div>
            <div class="advanced-fines-settings-tabs-controller settings-tabs-controller">
                <span v-for="subTab in subTabsList" :key="subTab.code" class="button-click" @click="state.subject = subTab.code"
                    :id="state.subject === subTab.code ? 'settings-tabs-controller-active' : ''">{{ subTab.text }}</span>
            </div>
            <div class="advanced-fines-settings">
                <div class="advanced-fines-settings-header">
                    <div class="advanced-fines-settings-column-dep advanced-fines-settings-item">
                        <span>{{ state.subjectName }}</span>
                    </div>
                    <div class="vert-delimeter"></div>
                    <div class="advanced-fines-settings-column-fine advanced-fines-settings-item">
                        <span>Сумма штрафа</span>
                    </div>
                    <div class="vert-delimeter"></div>
                    <div class="advanced-fines-settings-column-fine advanced-fines-settings-item">
                        <span>Допустимое время</span>
                    </div>
                    <div class="vert-delimeter"></div>
                    <div class="advanced-fines-settings-column-is-active advanced-fines-settings-item">
                        <span>Освобожден</span>
                    </div>
                </div>
                <div class="advanced-fines-settings-table">
                    <div v-for="subj in dataSets[state.subject as keyof typeof dataSets]" :key="subj.id">
                        <div @click="state.choosenId = subj.id"
                            :class="['advanced-fines-settings-table-row button-click',
                            { 'advanced-fines-settings-row-active': subj.id === state.choosenId }]"
                            >
                            <div class="advanced-fines-settings-column-dep advanced-fines-settings-item">
                                <span>{{ subj.name }}</span>
                            </div>
                            <div class="vert-delimeter"></div>
                            <div class="advanced-fines-settings-column-fine advanced-fines-settings-item">
                                <u-counter
                                  :type="state.subTab"
                                  :num="getCurrValue(subj.id, 'amount')"
                                  :showCurr="false"
                                  :step="10"
                                  :id = subj.id
                                  @counter-clicked="toModeTmpDict"
                                  :isActive="!getCurrValue(subj.id, 'free')"/>
                            </div>
                            <div class="vert-delimeter"></div>
                            <div class="advanced-fines-settings-column-fine advanced-fines-settings-item">
                                <u-counter
                                  :type="state.subTab"
                                  :num="getCurrValue(subj.id, state.subTab === 'late-fine' ? 'lateTime' : 'earlyLeave')"
                                  :showCurr="false"
                                  :step="10"
                                  :id = subj.id
                                  :subType = "'time'"
                                  @counter-clicked="toModeTmpDict"
                                  :isActive="!getCurrValue(subj.id, 'free')"/>
                            </div>
                            <div class="vert-delimeter"></div>
                            <div class="advanced-fines-settings-column-is-active advanced-fines-settings-item">
                                <img :src="getCurrValue(subj.id, 'free') ? checkBoxActive : checkBoxNotActive"
                                  class="button-click"
                                  @click="modeTmpDict(subj.id, 'free', !getCurrValue(subj.id, 'free'))">
                            </div>
                        </div>
                        <div class="row-delimeter"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <u-dialog v-if="dialogStates.showDialog"
      :header="dialogStates.dialogHeader"
      :msgText="dialogStates.dialogMsg"
      @click-res="closeDialog"
      :cancelButton="dialogStates.dialogCancelBtn"
      :showButtons="dialogStates.showSpinner ? false : true"
      :isLoadind="dialogStates.showSpinner"
    />
  </div>
  <div class="settings-action-btns"
    v-show="state.showOperationPanel">
      <button class="settings-action-btn button-click"
        id="cancel-btn" @click="preSaveTmpDict('cancel')">
        Отменить
      </button>
      <button class="settings-action-btn button-click"
        id="save-btn" @click="preSaveTmpDict('save')">
        Сохранить
      </button>
    </div>
</template>

<script setup lang="ts">
import { reactive, onMounted, watch } from 'vue'
import edit from '@/assets/icon/edit.svg'
import ok from '@/assets/icon/ok.svg'
import cancel from '@/assets/icon/cancel.svg'
import {
  Employee,
  Departments,
  Positions,
  FeeList,
  FeeListMap,
  FeePatchList,
  FeePatchMap,
  Cookies,
  Organization
} from '@/models'
import checkBoxActive from '@/assets/icon/check-box/active.svg'
import checkBoxNotActive from '@/assets/icon/check-box/not-active.svg'
import { subjects } from '@/maps'
import { useSettingsStore } from '@/stores/settings'
import { useCookesStore } from '@/stores/cookies'
import { useOrganazationStore } from '@/stores/organization'

interface PreparedDict {
  settings: { [key: string] : string },
  advansedSettings: FeePatchList
}

const state = reactive({
  tab: 'main',
  preparedDict: {} as PreparedDict,
  advancedSettings: [] as FeePatchList,
  aloudLateTime: 0,
  lateFine: 0,
  earlyLeaveFine: 0,
  operation: '',
  depChoosedName: '',
  depChoosedId: '',
  choosenId: '',
  subTab: 'late-fine',
  subject: 'organization',
  subjectName: '',
  cookies: {} as Cookies,
  orgId: '',
  showContolTab: false,
  organization: {} as Organization,
  showOperationPanel: false
})

const dialogStates = reactive({
  dialogHeader: '',
  dialogMsg: '',
  showDialog: false,
  dialogCancelBtn: false,
  showSpinner: false
})

interface Org {
  id: string,
  name: string,
  orgId: string
}

const dataSets = reactive({
  persons: [] as Employee[],
  departments: [] as Departments,
  positions: [] as Positions,
  organization: [] as Org[]
})

const settingsState = reactive({
  feeList: [] as FeeList
})

const subTabsList = [
  { code: 'organization', text: 'Организация' },
  { code: 'departments', text: 'Подразделение' },
  { code: 'positions', text: 'Должность' },
  { code: 'persons', text: 'Сотрудники' }
]

const mainSettingsStates = reactive({
  mainInput: '',
  telegram: '',
  email: '',
  password: '',
  name: '',
  lockPassword: '',
  timezone: null as number | null
})

const settingStore = useSettingsStore()
const cookies = useCookesStore()
const organization = useOrganazationStore()

interface AssociativeMap {
  [key: string]: string;
}

const associativeMap: AssociativeMap = {
// значение фронт: значение API
  'late-fine': 'late',
  'early-leave-fine': 'leave',
  persons: 'per',
  departments: 'dep',
  positions: 'pos',
  organization: 'org',
  lateTime: 'lateTime',
  earlyLeave: 'earlyLeave'
}

watch(() => state.subject, (newValue: string) => {
  state.subjectName = subjects[newValue as keyof typeof subjects]?.text
})

watch(() => state.preparedDict, (newValue) => {
  if ((newValue && Object.keys(newValue).length === 0) ||
    (newValue.advansedSettings?.length + (newValue.settings ? Object.keys(newValue.settings).length : 0) === 0)) {
    state.advancedSettings = []
    state.showOperationPanel = false
  } else {
    state.showOperationPanel = true
  }
}, { deep: true })

watch(() => state.organization, (newValue) => {
  if (newValue && Object.keys(newValue).length > 0) {
    dataSets.organization.push({
      id: newValue.orgId,
      name: newValue.name,
      orgId: newValue.orgId
    })
    state.showContolTab = newValue.options.feeControl
  }
})

const handleInput = (event: Event) => {
  const input = event.target as HTMLInputElement
  let value = input.value
  if (!/^\d+$/.test(input.value)) {
    value = value.slice(0, value.length - 1)
    mainSettingsStates.telegram = value
  }
}

onMounted(async () => {
  openDialog('Пожалуйста подождите!', 'Загружаем настройки', false, true)
  await cookies.getCookie()
  state.organization = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.orgId = cookies.getCookies.orgId
  await getMainSettings()
  state.subjectName = subjects[state.subject as keyof typeof subjects]?.text
  dataSets.persons = JSON.parse(sessionStorage.getItem('persons') || '[]')
  dataSets.departments = JSON.parse(sessionStorage.getItem('departments') || '[]')
  dataSets.positions = JSON.parse(sessionStorage.getItem('positions') || '[]')
  settingsState.feeList = JSON.parse(sessionStorage.getItem('fees') || '[]')
  closeDialog('cancel')
})

function updateTimezone (timezone: number) {
  mainSettingsStates.timezone = timezone
  state.preparedDict = { ...state.preparedDict, ...{ timezone } }
}

async function getFees () {
  await settingStore.getFeeAction(state.orgId)
  sessionStorage.setItem('fees', JSON.stringify(settingStore.getFeeList))
  settingsState.feeList = settingStore.getFeeList
}

async function getMainSettings (needToRebuildOrg: boolean = false) {
  if (needToRebuildOrg) {
    await organization.getOrganizationAction(state.orgId)
    state.organization = organization.getOrganization
    sessionStorage.setItem('organization', JSON.stringify(state.organization))
  }
  if (state.organization.chatId?.trim().length > 0) {
    mainSettingsStates.telegram = state.organization.chatId.slice(1)
  } else {
    mainSettingsStates.telegram = ''
  }
  mainSettingsStates.name = state.organization.name
  mainSettingsStates.email = state.organization.email
  mainSettingsStates.password = state.organization.lockPassword
  mainSettingsStates.lockPassword = state.organization.lockPassword
  mainSettingsStates.timezone = state.organization.timezone
}

function saveTmpResult (arg: string) {
  if (arg !== '') {
    let isError = false
    if (arg === 'name') {
      if (mainSettingsStates.name.trim().length === 0) {
        openDialog('Ошибка', 'Название организации не может быть пустым')
        isError = true
      } else {
        state.preparedDict.settings = { ...state.preparedDict.settings, ...{ name: mainSettingsStates.name } }
      }
    }
    if (arg === 'telegram') {
      if (mainSettingsStates.telegram.length < 6) {
        openDialog('Ошибка', 'ID Telegram должен минимум 6 символов')
        isError = true
      } else {
        state.preparedDict.settings = { ...state.preparedDict.settings, ...{ chatId: `-${mainSettingsStates.telegram}` } }
      }
    }
    if (arg === 'email') {
      if (!isValidEmail(mainSettingsStates.email.trim())) {
        openDialog('Ошибка', 'Адрес электронной почты введен не верно')
        isError = true
      } else {
        state.preparedDict.settings = { ...state.preparedDict.settings, ...{ email: mainSettingsStates.email } }
      }
    }
    if (arg === 'password') {
      state.preparedDict.settings = { ...state.preparedDict.settings, ...{ lockPassword: mainSettingsStates.password.trim() } }
    }
    if (!isError) {
      mainSettingsStates.mainInput = ''
    }
  }
}

function openDialog (dialogHeader: string, dialogText: string, cancelBtn: boolean = false, showSpinner: boolean = false) {
  dialogStates.dialogHeader = dialogHeader
  dialogStates.dialogMsg = dialogText
  dialogStates.showDialog = true
  dialogStates.dialogCancelBtn = cancelBtn
  dialogStates.showSpinner = showSpinner
}

async function closeDialog (res: string) {
  if (res === 'ok') {
    if (state.operation === 'clearTmpDict') {
      state.preparedDict = { settings: {}, advansedSettings: [] }
    }
    dialogStates.showDialog = false
    if (state.operation === 'saveTmpDict') {
      openDialog('Пожалуйста подождите!', '', false, true)
      let resText = ''
      let isError = false
      if (state.preparedDict.advansedSettings && state.preparedDict.advansedSettings.length > 0) {
        const result = await settingStore.updateFeeAction(state.orgId, state.preparedDict.advansedSettings)
        await getFees()
        resText = result?.message ? result?.message : ''
        isError = !result || result.status !== 'updated'
      }
      if (state.preparedDict.settings && Object.keys(state.preparedDict.settings).length > 0) {
        const result = await settingStore.updateMainSettingsAction(state.orgId, state.preparedDict.settings)
        await getMainSettings(true)
        resText = result?.message ? result?.message : ''
        isError = !result || result.status !== 'updated'
      }
      if (isError) {
        openDialog('Произошла ошибка!', resText)
      } else {
        openDialog('Настройки сохранены', resText)
        state.preparedDict = { settings: {}, advansedSettings: [] }
      }
    }
  } else {
    dialogStates.showDialog = false
  }
  state.operation = ''
}

function isValidEmail (email: string): boolean {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
}

function modeTmpDict (id: string, type: string, value: unknown) {
  const moddedRow = {
    subjectId: id,
    feeType: associativeMap[state.subTab],
    amount: type === 'amount'
      ? Number(value)
      : Number((serachMutatedData(id, 'amount') ? serachMutatedData(id, 'amount') : getCurrValue(id, 'amount'))),
    free: type === 'free'
      ? Boolean(value)
      : Boolean((serachMutatedData(id, 'free') ? serachMutatedData(id, 'free') : getCurrValue(id, 'free'))),
    lateTime: type === 'late-fine'
      ? Number(value)
      : Number((serachMutatedData(id, 'lateTime') ? serachMutatedData(id, 'lateTime') : getCurrValue(id, 'lateTime'))),
    earlyLeave: type === 'early-leave-fine'
      ? Number(value)
      : Number((serachMutatedData(id, 'earlyLeave') ? serachMutatedData(id, 'earlyLeave') : getCurrValue(id, 'earlyLeave')))
  }
  const index = state.advancedSettings.findIndex(item => item.subjectId === moddedRow.subjectId)
  if (index === -1) {
    state.advancedSettings.push(moddedRow)
  } else {
    state.advancedSettings[index] = moddedRow
  }
  state.preparedDict = { ...state.preparedDict, ...{ advansedSettings: state.advancedSettings } }
}

function serachMutatedData (id: string, type: string) {
  const mutatedData = state.advancedSettings.find(item => item.subjectId === id &&
      item.feeType === associativeMap[state.subTab]) as undefined | FeePatchMap
  if (mutatedData) {
    return mutatedData[type as keyof typeof mutatedData]
  }
}

function preSaveTmpDict (res: string) {
  if (res === 'cancel') {
    state.operation = 'clearTmpDict'
    openDialog('Отменить изменения?', 'Все не сохраненные изменения будут отменены.', true)
  }
  if (res === 'save') {
    state.operation = 'saveTmpDict'
    openDialog('Сохранить изменения?', 'Внесенные изменения будут сохранены.', true)
  }
}

function toModeTmpDict (arg: { [key: string] : string | number }) {
  const subTypeTmp = arg[Object.keys(arg)[2]]
  modeTmpDict(arg[Object.keys(arg)[1]].toString(),
    subTypeTmp === '' ? 'amount' : state.subTab,
    arg[Object.keys(arg)[0]])
}

function fineSum (id: string, type: string): number | string | boolean | null {
  const res = settingsState.feeList.find(item => item.subjectId === id &&
    item.feeType === associativeMap[state.subTab] &&
    item.subjectType === associativeMap[state.subject]) as undefined | FeeListMap
  if (!res) {
    if (type === 'amount' || type === 'lateTime' || type === 'earlyLeave') {
      return 0
    } else {
      return false
    }
  }
  return res[type as keyof typeof res]
}

function getCurrValue (id: string, type: string) {
  let perRes = null
  if (type === 'lateTime' || type === 'earlyLeave') {
    perRes = state.advancedSettings.find(item => item.subjectId === id && item[type as keyof typeof item] === associativeMap[type])
  } else {
    perRes = state.advancedSettings.find(item => item.subjectId === id && item.feeType === associativeMap[state.subTab])
  }
  if (perRes) {
    return perRes[type as keyof typeof perRes]
  } else {
    return fineSum(id, type)
  }
}

function closeInput () {
  if (mainSettingsStates.mainInput === 'name') {
    mainSettingsStates.name = state.organization.name.trim()
  }
  if (mainSettingsStates.mainInput === 'telegram') {
    mainSettingsStates.telegram = state.organization.chatId.slice(1)
  }
  if (mainSettingsStates.mainInput === 'email') {
    mainSettingsStates.email = state.organization.email
  }
  if (mainSettingsStates.mainInput === 'password') {
    mainSettingsStates.password = state.organization.lockPassword
  }
  mainSettingsStates.mainInput = ''
}
</script>

<style scoped>
.settings-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.settings-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.60vw;
}

.settings-tabs-controller {
    font: 0.83vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-a70);
    display: flex;
    gap: 2.34vw;
}

#settings-tabs-controller-active {
    color: var(--primary-font-color);
    text-decoration: underline;
    text-decoration-color: var(--primary-font-color-blue);
    text-decoration-thickness: 1px;
    text-underline-offset: 0.7vw;
}

.settings-action-btn {
    font: 0.83vw 'RedHatText-Bold', 'OpenSans', sans-serif;
    width: 8.33vw;
    padding: 0.78vw 1.82vw;
    border-radius: 20px;
    border: none;
    align-self: end;
}

.settings-action-btns {
    display: flex;
    gap: 0.78vw;
}

#save-btn {
    background-color: #042447;
    color: var(--primary-font-color);
}

#cancel-btn {
    background-color: #FFFFFF1A;
    color: var(--primary-font-color-a70);
}

.settings-main-row-header {
    font: 1.04vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
}

.settings-main-row-block {
    display: flex;
    height: 2.60vw;
    align-items: center;
    justify-content: space-between;
    gap: 5.21vw;
}

.settings-main-row-label-block,
.settings-main-row-label-block-timezone {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.4rem;
}

.settings-main-row-label-block {
  width: 19.45rem;
}

.settings-main-row-info {
    font: 0.83vw 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: #FFFFFF80;
}

.settings-main {
    display: flex;
    flex-direction: column;
    gap: 2.60vw;
    width: 20rem;
}

.settings-main-table {
    width: 40vw;
}

.input-icon-container {
    display: flex;
    align-items: center;
    position: relative;
}

.settings-main-input {
    height: 2.60vw;
    align-self: center;
    width: 20.83vw;
    border-radius: 1.4rem;
    padding-left: 1.30vw;
    padding-right: 1.30vw;
    background-color: #2D4B6B;
    box-shadow: none;
    outline: none;
    border: none;
    flex-grow: 1;
    font: 0.78vw 'RedHatDisplay-Regular', 'OpenSans Regular', sans-serif;
}

.input-icon {
    width: 0.73vw;
    position: absolute;
    right: 1vw;
    top: 50%;
    transform: translateY(-50%);
}

.img-block {
    display: flex;
    width: 1.30vw;
    gap: 0.5vw;
    margin-right: 1.5vw;
}

.img-block-counter {
    flex-direction: column;
}

.input-active {
    color: var(--primary-font-color)
}

.input-unactive {
    color: var(--primary-font-color-a70)
}

.settings-main-input[type="password"] {
    letter-spacing: 0.2rem;
}

.setting-control-header {
    font: 1.14rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color-blue);
}

.setting-control-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.advanced-fines-settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
}

.advanced-fines-settings-header, .advanced-fines-settings-table {
    display: flex;
    font: 0.565rem 'RedHatText-Regular', 'OpenSans Regular', sans-serif;
    color: var(--primary-font-color);
    background-color: #2D4B6B;
    padding: 0.8rem 0 0.8rem 0;
    border-radius: 1.2rem;
}

.advanced-fines-settings-table {
    display: flex;
    flex-direction: column;
}

.advanced-fines-settings-table-row {
    display: flex;
}

.vert-delimeter {
    border-left: 1px solid #FFFFFF1A;;
}

.advanced-fines-settings-column-dep {
    width: 15rem;
}

.advanced-fines-settings-column-fine {
    width: 6rem;
}

.advanced-fines-settings-column-is-active {
    width: 5rem;
}

.check-box {
    align-self: center;
    display: flex;
    justify-content: center;
}

.advanced-fines-settings-item {
    padding: 0.3rem 1.2rem 0.3rem 1.2rem;
    align-self: center;
}

.advanced-fines-settings-header-btn {
    background-color: #FFFFFF0D;
    box-sizing: border-box;
    border-radius: 0.8rem;
    width: 100%;
    display: flex;
    padding: 0.6rem 1.2rem 0.6rem 1.2rem;
}

.advanced-fines-settings-row-active {
    background-color: #FFFFFF0D;
    border-radius: 1rem;
    box-sizing: border-box;
}

.settings-main-main {
    width: 42.45vw;
}

@media (max-width: 950px) {
  .settings-wrapper {
    padding: 0;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    max-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .settings-header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10.18vw;
  }
  .settings-tabs-controller {
    font-size: 4.07vw;
    gap: 11.45vw;
  }

  #settings-tabs-controller-active {
    text-underline-offset: 2.04vw;
  }

  .settings-main-row-header {
    font-size: 4.07vw;
  }

  .settings-main-row-info {
    font-size: 3.56vw;
  }

  .settings-main-input {
    font-size: 3.82vw;
    height: 12.72vw;
    padding-right: 4vw;
    padding-left: 4vw;
  }
  .img-block {
    margin-right: 12vw;
    gap: 2vw;
  }
  .input-icon {
    width: 3.56vw;
    right: 4vw;
  }

  .settings-main {
    align-items: flex-start;
    gap: 5.09vw;
  }

  .settings-main-row-block {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .settings-main-input {
    width: 100%;
    border-radius: 10vw;
  }

  .settings-main-main {
    width: 100%;
  }

  .input-icon-container {
    width: 100%;
  }

  .setting-control-wrapper {
    flex-direction: column;
    gap: 2rem;
  }

  .settings-main-table{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .advanced-fines-settings{
    width: 100%;
    margin-top: 2rem;
  }

  .settings-action-btns {
    flex-direction: column;
  }
  .dropdown-timezone {
    height: 12.72vw;
    width: 75vw;
    padding: 0 4vw;
    border-radius: 10vw;
  }
  .settings-action-btns {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 6vw 4vw 6vw;
    gap: 1.5vw;
  }
  .settings-action-btn {
    width: 100%;
    font-size: 4.33vw;
    padding: 4vw 2vw;
    border-radius: 10vw;
  }
  .settings-tabs-controller {
    width: 85vw;
    overflow-x: scroll;
    padding-bottom: 1vw;
  }
  .advanced-fines-settings {
    width: 85vw;
  }
  .advanced-fines-settings-header, .advanced-fines-settings-table {
    overflow-x: scroll;
  }
}
</style>
