import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { axiosAzure } from '@/axios-setup'
import { Employee } from '@/models'
import { BinaryData } from '@/types'

export const useAzureStore = defineStore('azure', {
  state: () => ({
  }),
  actions: {
    async addPersonCompanyAzure (orgId: string, personId: string, personName: string) {
      try {
        const data = JSON.stringify({ name: personName, userData: personId })
        const response = await axiosAzure.post<Record<string, string>>(`/face/v1.0/persongroups/${orgId}/persons`,
          data,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async addPhotoPersonAzure (orgId: string, personId: string, image: BinaryData) {
      try {
        const response = await axiosAzure.post<Record<string, string>>(`/face/v1.0/persongroups/${orgId}/persons/${personId}/persistedFaces?detectionModel=detection_01`,
          image,
          {
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          })
        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async trainGroupAzure (orgId: string) {
      try {
        const response = await axiosAzure.post<Map<string, string>>(`/face/v1.0/persongroups/${orgId}/train`)
        if (response.status === 202) {
          return 'success'
        } else {
          return 'error'
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async delPersonCompanyAzure (person: Employee) {
      try {
        const response = await axiosAzure.delete<Record<string, string>>(`/face/v1.0/persongroups/${person.orgId}/persons/${person.faceId}`,
          {
            headers: { 'Content-Type': 'application/json' }
          })
        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
  }
})
